import * as React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AreaImages from './AreaImages';
import FinalDetails from './FinalDetails';
import Name from './Name';
import ReferenceImages from './ReferenceImages';
import Review from './Review';
import TattooColor from './TattooColor';
import TattooDetails from './TattooDetails';
import TattooInspiration from './TattooInspiration';
import TattooLocation from './TattooLocation';
import TattooSize from './TattooSize';
import FirstTattoo from './FirstTattoo';
import NewCustomer from './NewCustomer';
import Budget from './Budget';
import SkinTone from './SkinTone';
import Styles from './Styles';
import './Form.css';
import { User, Employee, FormValues } from '../../interfaces';

const formInitValues: FormValues = {
  firstName:
    sessionStorage.getItem('firstName') !== null
      ? sessionStorage.getItem('firstName')!
      : '',
  lastName:
    sessionStorage.getItem('lastName') !== null
      ? sessionStorage.getItem('lastName')!
      : '',
  location:
    sessionStorage.getItem('location') !== null
      ? sessionStorage.getItem('location')!
      : '',
  details:
    sessionStorage.getItem('details') !== null
      ? sessionStorage.getItem('details')!
      : '',
  size:
    sessionStorage.getItem('size') !== null
      ? sessionStorage.getItem('size')!
      : '',
  color:
    sessionStorage.getItem('color') !== null
      ? sessionStorage.getItem('color')!
      : '',
  inspiration:
    sessionStorage.getItem('inspiration') !== null
      ? sessionStorage.getItem('inspiration')!
      : '',
  styles:
    sessionStorage.getItem('styles') !== null
      ? sessionStorage.getItem('styles')!
      : '',
  refImages: [],
  areaImages: [],
  email:
    sessionStorage.getItem('email') !== null
      ? sessionStorage.getItem('email')!
      : '',
  budget: sessionStorage.getItem('budget')
    ? parseInt(JSON.parse(sessionStorage.getItem('budget')!), 10)
    : undefined,
  otherNotes:
    sessionStorage.getItem('otherNotes') !== null
      ? sessionStorage.getItem('otherNotes')!
      : '',
  firstTattoo:
    sessionStorage.getItem('firstTattoo') !== null
      ? sessionStorage.getItem('firstTattoo')!
      : '',
  newCustomer:
    sessionStorage.getItem('newCustomer') !== null
      ? sessionStorage.getItem('newCustomer')!
      : '',
  skinTone:
    sessionStorage.getItem('skinTone') !== null
      ? sessionStorage.getItem('skinTone')!
      : '',
  birthday:
    sessionStorage.getItem('birthday') !== null
      ? JSON.parse(sessionStorage.getItem('birthday')!)
      : undefined,
};

if (sessionStorage.getItem('refImageCount')) {
  const refImageCount = JSON.parse(sessionStorage.getItem('refImageCount')!);
  for (let i = 0; i < refImageCount; i += 1) {
    const imgUrl = sessionStorage.getItem(`refImage-${i}`);
    if (imgUrl) {
      formInitValues.refImages!.push(imgUrl);
    }
  }
}

if (sessionStorage.getItem('areaImageCount')) {
  const areaImageCount = JSON.parse(sessionStorage.getItem('areaImageCount')!);
  for (let i = 0; i < areaImageCount; i += 1) {
    const imgUrl = sessionStorage.getItem(`areaImage-${i}`);
    if (imgUrl) {
      formInitValues.areaImages!.push(imgUrl);
    }
  }
}

type Props = {
  progress: string;
  setProgress: any;
  employee: Employee;
  authString: string;
  user: User;
};

function Form({ progress, setProgress, employee, authString, user }: Props) {
  const [step, setStep] = React.useState(1);
  const [formValues, setFormValues] = React.useState(formInitValues);
  const [clickedEdit, setClickedEdit] = React.useState(false);

  React.useEffect(() => {
    if (sessionStorage.getItem('step') !== null) {
      setStep(JSON.parse(sessionStorage.getItem('step')!));
    }
  }, []);

  const nextStep = React.useCallback(() => {
    if (employee.intakeQuestions !== undefined) {
      employee.intakeQuestions.sort((a: any, b: any) => a.step - b.step);

      if (!clickedEdit) {
        for (let i = 0; i < employee.intakeQuestions.length; i += 1) {
          if (
            i < employee.intakeQuestions.length - 1 &&
            employee.intakeQuestions[i].step === step
          ) {
            setStep(employee.intakeQuestions[i + 1].step);
            sessionStorage.setItem(
              'step',
              JSON.stringify(employee.intakeQuestions[i + 1].step),
            );
            if (employee.intakeQuestions[i + 1].category !== progress) {
              setProgress(employee.intakeQuestions[i + 1].category);
              sessionStorage.setItem(
                'progress',
                employee.intakeQuestions[i + 1].category,
              );
            }
          }
        }

        if (step === 1 && employee.intakeQuestions[0].step !== 1) {
          setStep(employee.intakeQuestions[0].step);
          sessionStorage.setItem(
            'step',
            JSON.stringify(employee.intakeQuestions[0].step),
          );

          setProgress(employee.intakeQuestions[0].category);
          sessionStorage.setItem(
            'progress',
            employee.intakeQuestions[0].category,
          );
        }
      } else {
        setStep(
          employee.intakeQuestions[employee.intakeQuestions.length - 1].step,
        );
        setClickedEdit(false);
      }
    }
  }, [employee.intakeQuestions, progress, setProgress, step, clickedEdit]);

  const prevStep = React.useCallback(() => {
    if (employee.intakeQuestions !== undefined) {
      employee.intakeQuestions.sort((a: any, b: any) => a.step - b.step);

      for (let i = 0; i < employee.intakeQuestions.length; i += 1) {
        if (i > 0 && employee.intakeQuestions[i].step === step) {
          setStep(employee.intakeQuestions[i - 1].step);
          sessionStorage.setItem(
            'step',
            JSON.stringify(employee.intakeQuestions[i - 1].step),
          );
          if (employee.intakeQuestions[i - 1].category !== progress) {
            setProgress(employee.intakeQuestions[i - 1].category);
            sessionStorage.setItem(
              'progress',
              employee.intakeQuestions[i - 1].category,
            );
          }
        }
      }
    }
  }, [employee.intakeQuestions, progress, setProgress, step]);

  if (
    employee.intakeQuestions === undefined ||
    employee.intakeQuestions.length === 0
  ) {
    return (
      <div>
        There was an error loading questions for this Tattoo Artist. Try
        reloading the page and if there error persists, reach out to our support
        team for help
      </div>
    );
  }

  const backButton = (
    <div className="back" color="white">
      <ArrowBackIcon fontSize="small" onClick={() => prevStep()} />
    </div>
  );

  switch (step) {
    case 1:
      return (
        <div>
          {employee.intakeQuestions[0].step !== step ? backButton : null}
          <Name
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
          />
        </div>
      );
    case 2:
      return (
        <div>
          {employee.intakeQuestions[0].step !== step ? backButton : null}
          <TattooLocation
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
            authString={authString}
          />
        </div>
      );
    case 3:
      return (
        <div>
          {employee.intakeQuestions[0].step !== step ? backButton : null}
          <TattooDetails
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
          />
        </div>
      );
    case 4:
      return (
        <div>
          {employee.intakeQuestions[0].step !== step ? backButton : null}
          <TattooSize
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
          />
        </div>
      );
    case 5:
      return (
        <div>
          {employee.intakeQuestions[0].step !== step ? backButton : null}
          <TattooColor
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
            authString={authString}
          />
        </div>
      );
    case 6:
      return (
        <div>
          {employee.intakeQuestions[0].step !== step ? backButton : null}
          <TattooInspiration
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
          />
        </div>
      );
    case 7:
      return (
        <div>
          {employee.intakeQuestions[0].step !== step ? backButton : null}
          <Styles
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
          />
        </div>
      );
    case 8:
      return (
        <div>
          {employee.intakeQuestions[0].step !== step ? backButton : null}
          <ReferenceImages
            formValues={formValues}
            setFormValues={setFormValues}
            employee={employee}
            nextStep={nextStep}
          />
        </div>
      );
    case 9:
      return (
        <div>
          {employee.intakeQuestions[0].step !== step ? backButton : null}
          <AreaImages
            formValues={formValues}
            setFormValues={setFormValues}
            employee={employee}
            nextStep={nextStep}
          />
        </div>
      );
    case 10:
      return (
        <div>
          {employee.intakeQuestions[0].step !== step ? backButton : null}
          <FirstTattoo
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
          />
        </div>
      );
    case 11:
      return (
        <div>
          {employee.intakeQuestions[0].step !== step ? backButton : null}
          <NewCustomer
            formValues={formValues}
            setFormValues={setFormValues}
            employee={employee}
            nextStep={nextStep}
          />
        </div>
      );
    case 12:
      return (
        <div>
          {employee.intakeQuestions[0].step !== step ? backButton : null}
          <Budget
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
          />
        </div>
      );
    case 13:
      return (
        <div>
          {employee.intakeQuestions[0].step !== step ? backButton : null}
          <SkinTone
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
            authString={authString}
          />
        </div>
      );
    case 14:
      return (
        <div>
          {employee.intakeQuestions[0].step !== step ? backButton : null}
          <FinalDetails
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
          />
        </div>
      );
    case 15:
      return (
        <div>
          {employee.intakeQuestions[0].step !== step ? backButton : null}
          <Review
            formValues={formValues}
            employee={employee}
            setStep={setStep}
            authString={authString}
            user={user}
            setClickedEdit={setClickedEdit}
          />
        </div>
      );
    default:
      return (
        <div>
          There was an error loading questions for this Tattoo Artist. Try
          reloading the page and if there error persists, reach out to our
          support team for help
        </div>
      );
  }
}

export default Form;
