import * as React from 'react';
import { format } from 'date-fns';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Time from './Time';
import { getAvailableTimes } from '../../../../services/availability';
import { AvailableTimes } from '../../../../interfaces';

type Props = {
  selectedDay: Date;
  duration: number;
  employeeId: string;
  authString: string;
  setSelectedDateTime: React.Dispatch<React.SetStateAction<Date>>;
  setDateTimeIsSelected: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function TimeList({
  selectedDay,
  duration,
  employeeId,
  authString,
  setSelectedDateTime,
  setDateTimeIsSelected,
}: Props) {
  const [availableTimes, setAvailableTimes] =
    React.useState<AvailableTimes[]>();
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const dayHeaderFormat = 'eeee, MMMM d';

  React.useEffect(() => {
    async function fetchData() {
      setLoading(true);
      // Set available times based on onsched API call
      const tempAvailableTimes = await getAvailableTimes(
        format(selectedDay, 'yyyy-MM-dd'),
        format(selectedDay, 'yyyy-MM-dd'),
        duration,
        employeeId,
        authString,
      ).catch(() => setError(true));
      setAvailableTimes(tempAvailableTimes.availableTimes);
      setLoading(false);
    }
    fetchData();
  }, [selectedDay, authString, duration, employeeId]);

  return (
    <Grid item xs={12} sm={12} md={4} className="time-box">
      <Typography variant="body1">
        {format(selectedDay, dayHeaderFormat)}
      </Typography>
      <Stack spacing={1.25} className="time-stack">
        {availableTimes && !loading
          ? availableTimes.map(time => (
              <Time
                time={time}
                setSelectedDateTime={setSelectedDateTime}
                setDateTimeIsSelected={setDateTimeIsSelected}
                key={time.startDateTime}
              />
            ))
          : 'Loading...'}
        {availableTimes && availableTimes.length === 0 && !loading
          ? 'No available times on this day. Please select a different day'
          : null}
        {error ? 'Error loading available times on this day.' : null}
      </Stack>
    </Grid>
  );
}
