/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { differenceInYears } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormValues } from '../../interfaces';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
};

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is required'),

  birthday: yup
    .date()
    .required('Birthday is required')
    .test(
      'birthday',
      'Must be 18 years or older',
      value => differenceInYears(new Date(), new Date(value)) >= 18,
    ),
});

function FinalDetails({ formValues, setFormValues, nextStep }: Props) {
  const [value, setValue] = React.useState<Date | null>(null);

  const questionValues = {
    header: "Let's wrap this up... We don't want to keep you any longer.",
    progress: 5,
  };

  const formik = useFormik({
    initialValues: {
      email: formValues.email,
      birthday: formValues.birthday,
      otherNotes: formValues.otherNotes,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('email', values.email!);
      sessionStorage.setItem('birthday', JSON.stringify(values.birthday));
      sessionStorage.setItem('otherNotes', values.otherNotes!);
      nextStep();
    },
  });

  React.useEffect(() => {
    if (formValues.birthday) {
      setValue(formValues.birthday);
    }
  }, [formValues.birthday]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h2" className="form-header">
        {questionValues.header}
      </Typography>
      <div className="multi-input-container">
        <TextField
          id="email"
          name="email"
          label="Email"
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Birthday"
            value={value}
            onChange={newValue => {
              formik.setFieldValue('birthday', newValue);
              if (newValue) {
                setValue(newValue);
              }
              formik.setFieldTouched('birthday', true, true);
            }}
            renderInput={params => <TextField {...params} />}
          />
        </LocalizationProvider>
        <TextField
          id="otherNotes"
          name="otherNotes"
          multiline
          placeholder="Include any other information that you think would be helpful for your artist to know"
          value={formik.values.otherNotes}
          onChange={formik.handleChange}
          error={formik.touched.otherNotes && Boolean(formik.errors.otherNotes)}
          helperText={formik.touched.otherNotes && formik.errors.otherNotes}
          className="textarea"
          minRows={3}
        />
      </div>
      <Button
        variant="contained"
        className={`next ${formik.isValid ? 'done' : ''}`}
        type="submit">
        Next
      </Button>
      {formik.errors.birthday && formik.touched.birthday ? (
        <Typography variant="body1" className="error">
          {formik.errors.birthday}
        </Typography>
      ) : null}
    </form>
  );
}

export default FinalDetails;
