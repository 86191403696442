/* eslint-disable react/no-danger */
import * as React from 'react';
import {
  Typography,
  Paper,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
import { Employee } from '../../../interfaces';

interface Props {
  name: string;
  employee: Employee;
  setWaiver: React.Dispatch<React.SetStateAction<boolean>>;
  setLiabilityWaivedOn: React.Dispatch<React.SetStateAction<Date>>;
}

export default function Waiver({
  name,
  employee,
  setWaiver,
  setLiabilityWaivedOn,
}: Props) {
  const [tempWaiver, setTempWaiver] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleChange = () => {
    setTempWaiver(!tempWaiver);
  };

  const handleSubmit = () => {
    if (tempWaiver) {
      setWaiver(tempWaiver);
      setLiabilityWaivedOn(new Date());
    } else {
      setError(true);
    }
  };

  function createMarkup(waiver: string | undefined) {
    if (waiver) {
      return { __html: waiver };
    }
    return { __html: '' };
  }

  return (
    <div className="appointment-waiver">
      <Typography variant="h3">Liability Waiver</Typography>
      <Typography variant="body1">
        You must read and agree to {name}&apos;s liability waiver to confirm
        this appointment
      </Typography>
      <Paper elevation={1} className="waiver">
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={createMarkup(employee.liabilityWaiver)}
        />
      </Paper>
      <FormControlLabel
        control={<Checkbox />}
        className="waiver-checkbox"
        label="I accept"
        onChange={handleChange}
      />
      {error ? (
        <Typography variant="body1" color="primary">
          You must agree to the liability waiver to continue scheduling your
          appointment
        </Typography>
      ) : null}
      <Button
        variant="contained"
        className="next done"
        id="submit"
        onClick={handleSubmit}>
        Continue
      </Button>
    </div>
  );
}
