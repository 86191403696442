import axios from 'axios';
import API_URL from '../apiconfig';
import { Employee, IntakeQuestion } from '../interfaces';
import { getBusinessById } from './business';
import { getIntakeQuestion } from './intakeQuestion';

interface EmployeeToQuestion {
  employeeId: string;
  questionId: string;
}

export async function getEmployeeIntakeQuestions(
  employeesToQuestions: EmployeeToQuestion[],
  authorization: string,
) {
  const intakeQuestions: IntakeQuestion[] = [];

  try {
    employeesToQuestions.forEach(
      async (employeeToQuestion: EmployeeToQuestion) => {
        const intakeQuestion = await getIntakeQuestion(
          employeeToQuestion.questionId,
          authorization,
        ).catch(() => {});

        intakeQuestions.push(intakeQuestion);
      },
    );
  } catch (error) {
    throw new Error('Cannot get Intake Questions');
  }

  return intakeQuestions;
}

export async function getEmployeeByUrlPath(
  urlPath: string | undefined,
  authorization: string,
) {
  try {
    const employeeResult = await axios
      .get(`${API_URL}/v1/employees/${urlPath}`, {
        headers: {
          authorization,
        },
        params: {
          searchBy: 'urlPath',
          shop: true,
          intakeQuestions: true,
        },
      })
      .catch(() => {
        throw new Error('Cannot find employee');
      });

    if (employeeResult) {
      const tempEmployee = employeeResult.data.result;

      const intakeQuestions = await getEmployeeIntakeQuestions(
        tempEmployee.employeesToQuestions,
        authorization,
      );

      const business = await getBusinessById(
        tempEmployee.employeesToBusinesses[0].businessId,
        authorization,
      );

      const employee: Employee = {
        employeeId: tempEmployee.employeeId,
        onschedId: tempEmployee.onschedResourceId,
        displayName: tempEmployee.displayName,
        about: tempEmployee.about,
        profileImage: tempEmployee.profileImage,
        urlPath: tempEmployee.urlPath,
        standardDesignMessage: tempEmployee.standardDesignMessage,
        cancellationPolicyText: tempEmployee.cancellationPolicyText,
        minCancellationDays: tempEmployee.minCancellationDays,
        maxCancellationTimes: tempEmployee.maxCancellationTimes,
        user: tempEmployee.user,
        intakeQuestions,
        businesses: [business],
      };

      return employee;
    }

    return false;
  } catch (error) {
    throw new Error('Cannot get employee');
  }
}

export async function getEmployeeById(
  employeeId: string,
  authorization: string,
) {
  try {
    const employeeResult = await axios
      .get(`${API_URL}/v1/employees/${employeeId}`, {
        headers: {
          authorization,
        },
        params: {
          searchBy: 'employeeId',
          shop: true,
        },
      })
      .catch(() => {
        throw new Error('Cannot find employee');
      });

    if (employeeResult) {
      const tempEmployee = employeeResult.data.result;

      const business = await getBusinessById(
        tempEmployee.employeesToBusinesses[0].businessId,
        authorization,
      );

      const employee: Employee = {
        employeeId: tempEmployee.employeeId,
        onschedId: tempEmployee.onschedResourceId,
        displayName: tempEmployee.displayname,
        about: tempEmployee.about,
        profileImage: tempEmployee.profileImage,
        urlPath: tempEmployee.urlPath,
        standardDesignMessage: tempEmployee.standardDesignMessage,
        cancellationPolicyText: tempEmployee.cancellationPolicyText,
        minCancellationDays: tempEmployee.minCancellationDays,
        maxCancellationTimes: tempEmployee.maxCancellationTimes,
        user: tempEmployee.user,
        liabilityWaiver: tempEmployee.liabilityWaiver,
        businesses: [business],
      };

      return employee;
    }

    return false;
  } catch (error) {
    throw new Error('Cannot get employee');
  }
}
