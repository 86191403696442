import * as React from 'react';
import Typography from '@mui/material/Typography';
import { AvailableTimes } from '../../../../interfaces';

type Props = {
  time: AvailableTimes;
  setSelectedDateTime: React.Dispatch<React.SetStateAction<Date>>;
  setDateTimeIsSelected: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Time({
  time,
  setSelectedDateTime,
  setDateTimeIsSelected,
}: Props) {
  const dateTime = new Date(time.startDateTime);

  function clickHandle(datetime: Date) {
    setSelectedDateTime(datetime);
    setDateTimeIsSelected(true);
  }

  return (
    <div
      role="presentation"
      className="time-item"
      onClick={() => {
        clickHandle(dateTime);
      }}>
      <Typography variant="h3">{time.displayTime}</Typography>
    </div>
  );
}
