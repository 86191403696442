import axios from 'axios';
import API_URL from '../apiconfig';
import { Business, Employee } from '../interfaces';

interface EmployeeToBusiness {
  businessId: string;
  employeeId: string;
}

export async function getBusinessById(
  businessId: string,
  authorization: string,
) {
  try {
    const business = await axios.get(`${API_URL}/v1/businesses/${businessId}`, {
      headers: {
        authorization,
      },
    });

    return business.data.result;
  } catch (error) {
    throw new Error('Cannot get business');
  }
}

async function getEmployee(employeeId: string, authorization: string) {
  try {
    const employeeResult = await axios.get(
      `${API_URL}/v1/employees/${employeeId}`,
      {
        headers: {
          authorization,
        },
        params: {
          searchBy: 'employeeId',
          shop: false,
        },
      },
    );

    return employeeResult.data.result;
  } catch (error) {
    throw new Error('Cannot get Employee');
  }
}

export async function getShopsEmployees(
  employees: EmployeeToBusiness[],
  authorization: string,
) {
  const tempEmployees: Employee[] = [];

  try {
    await Promise.all(
      employees.map(async employeeToBusiness => {
        tempEmployees.push(
          await getEmployee(employeeToBusiness.employeeId, authorization).catch(
            () => {},
          ),
        );
      }),
    );
  } catch (error) {
    throw new Error('Cannot get Shops Employees');
  }

  return tempEmployees;
}

export async function getBusinessByUrlPath(
  urlPath: string | undefined,
  authorization: string,
) {
  try {
    const shopResult = await axios
      .get(`${API_URL}/v1/businesses`, {
        headers: {
          authorization,
        },
        params: {
          limit: 1,
          UrlPath: urlPath,
          artist: true,
        },
      })
      .catch(() => {
        throw new Error('Cannot find shop');
      });

    if (shopResult) {
      const tempShop = shopResult.data.result[0];

      const employees = await getShopsEmployees(
        tempShop.employeesToBusinesses,
        authorization,
      );

      const shop: Business = {
        businessId: tempShop.businessId,
        name: tempShop.name,
        streetAddress: tempShop.streetAddress,
        streetAddress2: tempShop.streetAddress2,
        city: tempShop.city,
        state: tempShop.state,
        zip: tempShop.zip,
        thumbnailImage: tempShop.thumbnailImage,
        coverImage: tempShop.coverImage,
        urlPath: tempShop.urlPath,
        isActive: tempShop.isActive,
        employees,
      };

      return shop;
    }

    return false;
  } catch (error) {
    throw new Error('Cannot get shop');
  }
}
