import axios from 'axios';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../firebase';
import API_URL from '../apiconfig';
import { User, Employee, FormValues, Appointment } from '../interfaces';

export async function getAppointment(
  appointmentId: string | undefined,
  authorization: string,
) {
  try {
    const response = await axios.get(
      `${API_URL}/v1/appointments/${appointmentId}`,
      {
        headers: {
          authorization,
        },
      },
    );

    return response.data.result;
  } catch (error) {
    throw new Error('Cannot get appointment');
  }
}

export async function createAppointment(
  formValues: FormValues,
  employee: Employee,
  user: User,
  authorization: string,
  imageResponse: {
    refImgsId: string[] | undefined;
    areaImgsId: string[] | undefined;
  },
) {
  try {
    const payload: Appointment = {
      employeeId: employee.employeeId,
      customerId: user.userId,
      status: 'REQUESTED',
    };

    if (formValues.budget) {
      payload.budget = formValues.budget;
    }

    if (formValues.size) {
      payload.tattooSize = formValues.size;
    }

    if (formValues.location) {
      payload.tattooLocation = formValues.location;
    }

    if (formValues.firstTattoo) {
      if (formValues.firstTattoo === 'true') {
        payload.isFirstTattoo = true;
      } else {
        payload.isFirstTattoo = false;
      }
    }

    if (formValues.newCustomer) {
      if (formValues.newCustomer === 'true') {
        payload.isNewCustomer = true;
      } else {
        payload.isNewCustomer = false;
      }
    }
    if (formValues.color) {
      payload.tattooColor = formValues.color;
    }
    if (formValues.details) {
      payload.tattooDetails = formValues.details;
    }
    if (formValues.inspiration) {
      payload.tattooInspiration = formValues.inspiration;
    }
    if (formValues.otherNotes) {
      payload.otherNotes = formValues.otherNotes;
    }
    if (formValues.skinTone) {
      payload.skinTone = formValues.skinTone;
    }
    if (formValues.styles) {
      payload.prefTattooStyle = formValues.styles;
    }
    if (imageResponse.refImgsId) {
      payload.refImgsId = imageResponse.refImgsId;
    }
    if (imageResponse.areaImgsId) {
      payload.areaImgsId = imageResponse.areaImgsId;
    }

    const response = await axios.post(`${API_URL}/v1/appointments`, payload, {
      headers: {
        authorization,
      },
    });

    return response.data.result;
  } catch (error) {
    throw new Error('Cannot create appointment');
  }
}

export const uploadFile = async (
  file: string,
  storageUrl: string,
): Promise<string> => {
  const storageRef = ref(storage, storageUrl);

  const blobObject = await fetch(file).then(r => r.blob());

  await uploadBytes(storageRef, blobObject).catch(error => error.code);
  return getDownloadURL(storageRef);
};

async function uploadFiles(files: string[], user: User) {
  const images: string[] = await Promise.all(
    files.map(
      (item, index) =>
        // eslint-disable-next-line no-async-promise-executor
        new Promise<string>(async res => {
          const timestamp = Date.now();
          const storageUrl = `users/${user.authServiceId}/appointment_${timestamp}_${index}`;
          const url = await uploadFile(item, storageUrl);
          res(url);
        }),
    ),
  );
  return images;
}

// eslint-disable-next-line consistent-return
export async function uploadAndGetUrls(formValues: FormValues, user: User) {
  const refImgsId = formValues.refImages
    ? await uploadFiles(formValues.refImages, user)
    : [];
  const areaImgsId = formValues.areaImages
    ? await uploadFiles(formValues.areaImages, user)
    : [];

  return {
    refImgsId,
    areaImgsId,
  };
}

export async function scheduleAppointment(
  startDateTime: Date,
  endDateTime: Date,
  appointmentId: string,
  authorization: string,
) {
  try {
    const appointmentResponse = await axios.patch(
      `${API_URL}/v1/appointments/${appointmentId}`,
      {
        startDateTime,
        endDateTime,
        status: 'SCHEDULED',
      },
      {
        headers: {
          authorization,
        },
      },
    );

    return appointmentResponse;
  } catch (error) {
    throw new Error('Cannot schedule appointment');
  }
}

export async function confirmAppointment(
  paymentStatus: string,
  paymentMeta: string,
  appointmentId: string,
  authorization: string,
  newStatus: string,
  liabilityWaivedOn: Date,
) {
  try {
    const appointmentResponse = await axios.patch(
      `${API_URL}/v1/appointments/${appointmentId}`,
      {
        paymentStatus,
        paymentMeta,
        status: newStatus,
        liabilityWaivedOn,
      },
      {
        headers: {
          authorization,
        },
      },
    );

    return appointmentResponse;
  } catch (error) {
    throw new Error('Cannot confirm appointment');
  }
}

export async function reschedule(
  startDateTime: Date,
  endDateTime: Date,
  appointmentId: string,
  authorization: string,
) {
  try {
    const appointmentResponse = await axios.patch(
      `${API_URL}/v1/appointments/${appointmentId}`,
      {
        startDateTime,
        endDateTime,
        status: 'RESCHEDULED',
      },
      {
        headers: {
          authorization,
        },
      },
    );

    return appointmentResponse;
  } catch (error) {
    throw new Error('Cannot re-schedule appointment');
  }
}

export async function cancel(appointmentId: string, authorization: string) {
  try {
    const appointmentResponse = await axios.patch(
      `${API_URL}/v1/appointments/${appointmentId}`,
      {
        status: 'CANCELED',
      },
      {
        headers: {
          authorization,
        },
      },
    );

    return appointmentResponse;
  } catch (error) {
    throw new Error('Cannot cancel appointment');
  }
}
