import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import AddCircleIcon from '@mui/icons-material/AddCircle';

type Props = {
  setIsValid: any;
  objectURLs: string[];
  setObjectURLs: any;
  setFileSize: any;
  setFileMax: any;
};

function ImageUpload({
  setIsValid,
  objectURLs,
  setObjectURLs,
  setFileSize,
  setFileMax,
}: Props) {
  const [imageFiles, setImageFiles] = React.useState<File[]>([]);

  function onImageChange(e: any) {
    const { files } = e.target;
    const validImageFiles = [];

    let fileSizeFlag = true;
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      if (file.size > 20000000) {
        fileSizeFlag = false;
      }
      validImageFiles.push(file);
    }

    // If file size check didn't pass, set the error message and return false
    if (!fileSizeFlag) {
      setFileSize(false);
      setIsValid(false);
      return false;
    }

    if (validImageFiles.length > 6) {
      setFileMax(false);
      setIsValid(false);
      return false;
    }

    if (validImageFiles.length < 1) {
      setIsValid(false);
      return false;
    }

    setIsValid(true);
    setImageFiles(validImageFiles);
    return true;
  }

  React.useEffect(() => {
    const tempImages: any[] = [];
    const fileReaders: any = [];
    let isCancel = false;
    if (imageFiles.length) {
      imageFiles.forEach(file => {
        const fileReader = new FileReader();
        fileReaders.push(fileReader);
        fileReader.onload = () => {
          const { result } = fileReader;
          if (result) {
            tempImages.push(result);
          }
          if (tempImages.length === imageFiles.length && !isCancel) {
            setObjectURLs(tempImages);
          }
        };
        fileReader.readAsDataURL(file);
      });
    }
    return () => {
      isCancel = true;
      fileReaders.forEach((fileReader: any) => {
        if (fileReader.readyState === 1) {
          fileReader.abort();
        }
      });
    };
  }, [imageFiles, setObjectURLs]);

  // For each objectURL, create a grid element to display the image
  function printImages() {
    const imageURLElems: React.ReactNode[] = [];
    objectURLs.forEach((src: string, index: number) => {
      imageURLElems.push(
        // eslint-disable-next-line react/no-array-index-key
        <Grid item md={3} key={index}>
          <img src={src} alt="Uploaded" />
        </Grid>,
      );
    });
    return imageURLElems;
  }

  return (
    <div className="imageUpload">
      <Grid container spacing={2} className="imagePreviews">
        {printImages()}
        <Grid item md={3}>
          <InputLabel className="image-label">
            <div>
              <div className="upload-icon">
                <AddCircleIcon color="primary" fontSize="large" />
              </div>
              <div className="upload-text">
                <Typography variant="body1">Add Photos</Typography>
              </div>
            </div>
            <input
              className="image-input"
              type="file"
              name="image-upload"
              onChange={onImageChange}
              multiple
              hidden
              accept="image/*"
            />
          </InputLabel>
        </Grid>
      </Grid>
    </div>
  );
}

export default ImageUpload;
