import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './ShopHeader.css';
import { Business } from '../../interfaces';

type Props = {
  business: Business;
};

function BusinessHeader({ business }: Props) {
  const [scroll, setScroll] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 225) {
        setScroll(true);
      }
    });
  }, []);

  let address = business.streetAddress;
  address += business.streetAddress2 ? `, ${business.streetAddress2}` : '';

  return (
    <Box>
      <img
        className={scroll ? 'shop-cover scroll' : 'shop-cover'}
        src={business.coverImage}
        alt={business.name}
      />
      <div className={scroll ? 'shop-header scroll' : 'shop-header'}>
        <div className="thumbnail-header">
          <img
            className={scroll ? 'shop-thumbnail scroll' : 'shop-thumbnail'}
            src={business.thumbnailImage}
            alt={business.name}
          />
          <div
            className={
              scroll ? 'shop-header-details scroll' : 'shop-header-details'
            }>
            <Typography variant="h1" gutterBottom>
              {business.name}
            </Typography>
            <Typography variant="body1">{address}</Typography>
            <Typography variant="body1">
              {business.city}, {business.state} {business.zip}
            </Typography>
          </div>
        </div>
        <Typography variant="h2" className="shop-header">
          Select the artist that you want to work with...
        </Typography>
      </div>
    </Box>
  );
}

export default BusinessHeader;
