import { Stack } from '@mui/material';
import PaymentTableRow from './PaymentTableRow';
import { getPriceText } from '../../../utils/appointment';
import { Appointment } from '../../../interfaces';
import PaymentTotalRow from './PaymentTotalRow';

interface Props {
  appointment: Appointment;
  status: string;
}

export default function PaymentTable({ appointment, status }: Props) {
  let depositText = '';
  let convenienceText = '';
  let totalText = '';

  // Set up page text when scheduling a new appointment with or without a deposit fee
  if (appointment.depositPrice) {
    const totalPrice =
      Math.round(appointment.depositPrice * 1.035 * 100.0) / 100;
    const convenienceFee = totalPrice - appointment.depositPrice;
    depositText = `$${appointment.depositPrice.toFixed(2)}`;
    convenienceText = `$${convenienceFee.toFixed(2)}`;
    totalText = `$${totalPrice.toFixed(2)}`;
  }

  switch (status) {
    case 'schedule':
      return (
        <div className="payment-details">
          <Stack spacing={1.25} className="payment-stack">
            <PaymentTableRow
              label="Tattoo Price"
              amount={getPriceText(
                appointment.priceType!,
                appointment.minPrice,
                appointment.maxPrice,
                appointment.hourlyPrice,
                appointment.price,
              )}
            />
            <PaymentTableRow
              label="Deposit Fee (paid to artist)"
              amount={depositText}
            />
            <PaymentTableRow
              label="Service Fee (~3.5%)"
              amount={convenienceText}
            />
            <PaymentTotalRow label="Total due today" amount={totalText} />
          </Stack>
        </div>
      );

    case 'reschedule':
      return (
        <div className="payment-details">
          <Stack spacing={1.25} className="payment-stack">
            <PaymentTableRow
              label="Tattoo Price"
              amount={getPriceText(
                appointment.priceType!,
                appointment.minPrice,
                appointment.maxPrice,
                appointment.hourlyPrice,
                appointment.price,
              )}
            />
            <PaymentTableRow
              label="Rescheduling Fee (paid to artist)"
              amount={depositText}
            />
            <PaymentTableRow
              label="Service Fee (~3.5%)"
              amount={convenienceText}
            />
            <PaymentTotalRow label="Total due today" amount={totalText} />
          </Stack>
        </div>
      );

    default:
      return (
        <div className="payment-details">
          <Stack spacing={1.25} className="payment-stack">
            <PaymentTableRow
              label="Tattoo Price"
              amount={getPriceText(
                appointment.priceType!,
                appointment.minPrice,
                appointment.maxPrice,
                appointment.hourlyPrice,
                appointment.price,
              )}
            />
            <PaymentTableRow
              label="Deposit Fee (paid to artist)"
              amount={depositText}
            />
            <PaymentTableRow
              label="Service Fee (~3.5%)"
              amount={convenienceText}
            />
            <PaymentTotalRow label="Total due today" amount={totalText} />
          </Stack>
        </div>
      );
  }
}
