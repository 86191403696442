import * as React from 'react';
import Phone from './Phone';
import Code from './Code';
import '../Form/Form.css';

const defaultValues = {
  step: 1,
  userPhone: '',
  code: '',
  loggedIn: false,
};

export default function Login() {
  const [loginValues, setLoginValues] = React.useState(defaultValues);

  const prevStep = React.useCallback(() => {
    const currStep = loginValues.step;
    const newStep = currStep - 1;
    setLoginValues({
      ...loginValues,
      step: newStep,
    });
  }, [loginValues]);

  switch (loginValues.step) {
    case 1:
      return (
        <Phone loginValues={loginValues} setLoginValues={setLoginValues} />
      );
    case 2:
      return <Code loginValues={loginValues} prevStep={prevStep} />;
    default:
      return <div>Error</div>;
  }
}
