import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Appointment, Employee } from '../../interfaces';
import './Booking.css';
import DetailSection from './Appointment/DetailSection';
import { createDetailitems } from '../../utils/appointment';
import { cancel } from '../../services/appointment';

type Props = {
  appointment: Appointment;
  employee: Employee;
  aptDate: Date | undefined;
  authString: string;
};

function AppointmentPage({
  appointment,
  employee,
  aptDate,
  authString,
}: Props) {
  const detailItems = createDetailitems(appointment, employee, aptDate);

  const cancelAppointment = async () => {
    if (!appointment.appointmentId) {
      return;
    }

    // Cancel the appointment
    await cancel(appointment.appointmentId, authString).catch(() => {});
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={0} className="schedule-grid" columns={12}>
        <Grid md={6} className="schedule-grid-item detail-section">
          <DetailSection
            title="Your Appointment"
            employee={employee}
            detailItems={detailItems}
          />
        </Grid>
        <Grid md={6} className="schedule-grid-item action">
          <div className="action-buttons">
            <Button
              variant="contained"
              color="primary"
              href={`/reschedule/${appointment.appointmentId}`}>
              Reschedule
            </Button>
            <Button variant="contained" onClick={cancelAppointment}>
              Cancel
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default AppointmentPage;
