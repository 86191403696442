import * as React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';
import MenuItem from '@mui/material/MenuItem';
import { FormValues, Choice } from '../../interfaces';
import { getChoices } from '../../services/choices';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
  authString: string;
};

const validationSchema = yup.object({
  skinTone: yup.string().required('Please select a tatoo location'),
});

function SkinTone({ formValues, setFormValues, nextStep, authString }: Props) {
  const [skinTones, setSkinTones] = React.useState<Choice[]>();
  const [loading, setLoading] = React.useState(true);

  const questionValues = {
    header: "What's your skin tone?",
    progress: 5,
  };

  React.useEffect(() => {
    async function fetchData() {
      const tempSkinTones = await getChoices('SKIN_TONE', authString).catch(
        () => {},
      );
      tempSkinTones.sort((a: Choice, b: Choice) => {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        if (textA < textB) {
          return -1;
        }
        if (textA > textB) {
          return 1;
        }
        return 0;
      });
      setSkinTones(tempSkinTones);
      setLoading(false);
    }
    if (loading) {
      fetchData();
    }
  });

  const formik = useFormik({
    initialValues: {
      skinTone: formValues.skinTone,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('skinTone', values.skinTone!);
      nextStep();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h2" className="form-header">
        {questionValues.header}
      </Typography>
      {loading ? 'Loading...' : null}
      {!loading && !skinTones ? 'Error loading tattoo locations' : null}
      {skinTones && skinTones.length > 0 ? (
        <TextField
          id="skinTone"
          select
          name="skinTone"
          label="Skin Tone"
          value={formik.values.skinTone}
          onChange={formik.handleChange}
          error={formik.touched.skinTone && Boolean(formik.errors.skinTone)}
          helperText={formik.touched.skinTone && formik.errors.skinTone}>
          {skinTones.map(skinTone => (
            <MenuItem key={skinTone.name} value={skinTone.name}>
              {skinTone.name}
            </MenuItem>
          ))}
        </TextField>
      ) : null}
      <Button
        variant="contained"
        className={`next ${formik.isValid ? 'done' : ''}`}
        type="submit">
        Next
      </Button>
    </form>
  );
}

export default SkinTone;
