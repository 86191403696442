import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { addMinutes } from 'date-fns';
import { Appointment, Employee } from '../../../interfaces';
import PaymentTable from './PaymentTable';
import CheckoutForm from './CheckoutForm';
import API_URL from '../../../apiconfig';
import {
  confirmAppointment,
  scheduleAppointment,
  reschedule,
} from '../../../services/appointment';
import STRIPE_PK from '../../../stripe';

interface Props {
  appointment: Appointment;
  authString: string;
  status: string;
  startDateTime: Date;
  employee: Employee;
  liabilityWaivedOn: Date;
}

// TODO - update with stripe actual key
const stripePromise = loadStripe(STRIPE_PK);

export default function PaymentSection({
  appointment,
  authString,
  status,
  startDateTime,
  employee,
  liabilityWaivedOn,
}: Props) {
  const [clientSecret, setClientSecret] = React.useState('');
  const [isScheduled, setIsScheduled] = React.useState(false);
  const [needsPayment, setNeedsPayment] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [submitError, setSubmitError] = React.useState(false);

  React.useEffect(() => {
    if (status === 'schedule') {
      // Set state variables of page so we know if we need to schedule the appointment and collect a deposit
      if (appointment.status === 'ACCEPTED') {
        setIsScheduled(false);
        if (appointment.depositPrice && appointment.depositPrice > 0) {
          setNeedsPayment(true);
        } else {
          setNeedsPayment(false);
        }
      }

      // Set state variables of page so we know if we need to schedule the appointment and collect a deposit
      if (appointment.status === 'SCHEDULED') {
        setIsScheduled(true);
        if (appointment.depositPrice && appointment.depositPrice > 0) {
          setNeedsPayment(true);
        } else {
          setNeedsPayment(false);
        }
      }
    }

    if (status === 'reschedule') {
      // Set state variables of page so we know if we need to schedule the appointment and collect a deposit
      if (
        employee.maxCancellationTimes === 0 ||
        !employee.maxCancellationTimes
      ) {
        setNeedsPayment(true);
        return;
      }

      if (
        appointment.rescheduledByCust &&
        appointment.rescheduledByCust >= employee.maxCancellationTimes
      ) {
        setNeedsPayment(true);
        return;
      }

      setNeedsPayment(false);
    }
  }, [appointment, status, employee.maxCancellationTimes]);

  const scheduleAndConfirm = async () => {
    setSubmitting(true);

    if (needsPayment) {
      setSubmitting(false);
      setSubmitError(true);
      setErrorMessage(
        'An unexpected error occured scheduling this appointment. Please reach out to our support team for assistance',
      );
      return;
    }

    // If appointment ID or duration don't exist, set and display error message. Don't continue submit function
    if (!appointment.appointmentId || !appointment.appointmentLengthInMinutes) {
      setSubmitError(true);
      setErrorMessage(
        'An unexpected error occured scheduling this appointment. Please reach out to our support team for assistance',
      );
      setSubmitting(false);
      return;
    }

    if (status === 'schedule') {
      if (!isScheduled) {
        // Set end date time based on start time and duration
        const endDateTime = addMinutes(
          startDateTime,
          appointment.appointmentLengthInMinutes,
        );

        // Schedule the appointment in the database
        await scheduleAppointment(
          startDateTime,
          endDateTime,
          appointment.appointmentId,
          authString,
        ).catch(() => {
          setSubmitError(true);
          setErrorMessage(
            'There was an error scheduling your appointment. Please try again or reach out to our support team for assistance',
          );
          setSubmitting(false);
        });
      }

      await confirmAppointment(
        'SUCCESS',
        '',
        appointment.appointmentId,
        authString,
        'CONFIRMED',
        liabilityWaivedOn,
      )
        .then(() => {
          window.location.replace(`/booking/${appointment.appointmentId}`);
        })
        .catch(() => {
          setSubmitError(true);
          setSubmitting(false);
          setErrorMessage(
            'There was an error scheduling your appointment. Please try again or reach out to our support team for assitance.',
          );
        });
    }

    if (status === 'reschedule') {
      // Set end date time based on start time and duration
      const endDateTime = addMinutes(
        startDateTime,
        appointment.appointmentLengthInMinutes,
      );

      // Reschedule the appointment
      await reschedule(
        startDateTime,
        endDateTime,
        appointment.appointmentId,
        authString,
      ).catch(() => {
        setSubmitError(true);
        setErrorMessage(
          'There was an error scheduling your appointment. Please try again or reach out to our support team for assistance',
        );
        setSubmitting(false);
      });
    }
  };

  React.useEffect(() => {
    let price;
    if (appointment.depositPrice) {
      price = Math.round(appointment.depositPrice * 100 * 1.035);
    }

    // Create PaymentIntent as soon as the page loads
    fetch(`${API_URL}/v1/payment_intent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authString,
      },
      body: JSON.stringify({
        price,
        appointmentId: appointment.appointmentId || '',
      }),
    })
      .then(res => res.json())
      .then(data => setClientSecret(data.result.client_secret));
  }, [appointment.appointmentId, appointment.depositPrice, status, authString]);

  // Set up stripe appearance settings
  const appearance = {
    variables: {
      colorPrimary: '#ff3008',
      colorDanger: '#ff3008',
      colorBackground: '#ffffff',
      colorText: '#333333',
      fontFamily: 'Montserrat',
      spacingUnit: '4px',
      borderRadius: '4px',
      fontSizeBase: '18px',
      fontWeightBold: '600',
      fontWeightLight: '400',
      colorTextPlaceholder: '#BDBDBD',
    },
  };

  const fonts = [
    { cssSrc: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400' },
  ];

  const options = {
    clientSecret,
    appearance,
    fonts,
  };

  return (
    <div className="payment-section">
      {status === 'schedule' || needsPayment ? (
        <PaymentTable appointment={appointment} status={status} />
      ) : null}

      {needsPayment && options.clientSecret === '' ? 'Loading...' : null}

      {needsPayment && options.clientSecret !== '' ? (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            appointment={appointment}
            authString={authString}
            startDateTime={startDateTime}
            isScheduled={isScheduled}
            status={status}
            liabilityWaivedOn={liabilityWaivedOn}
          />
        </Elements>
      ) : null}

      {!needsPayment && !submitting ? (
        <Button
          variant="contained"
          className="next done"
          id="submit"
          onClick={scheduleAndConfirm}>
          Confirm Appointment
        </Button>
      ) : null}

      {!needsPayment && submitting ? (
        <div>
          <LoadingButton loading variant="contained" className="next">
            Submit
          </LoadingButton>
          <Typography variant="body1" className="loading-text">
            Confirming your appointment... Please do not refresh the page
          </Typography>
        </div>
      ) : null}

      {submitError ? (
        <Typography variant="body1">{errorMessage}</Typography>
      ) : null}
    </div>
  );
}
