import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FormValues, Employee } from '../../interfaces';
import ImageUpload from './ImageUpload';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  employee: Employee;
  nextStep: () => void;
};

function AreaImages({ formValues, setFormValues, employee, nextStep }: Props) {
  const [objectURLs, setObjectURLs] = React.useState<string[]>([]);
  const [isValid, setIsValid] = React.useState(false);
  const [fileSize, setFileSize] = React.useState(true);
  const [fileMax, setFileMax] = React.useState(true);
  const [noFiles, setNoFiles] = React.useState(true);
  const [loading, setLoading] = React.useState(true);

  const artistName = employee.displayName
    ? employee.displayName
    : `${employee.user.firstName} ${employee.user.lastName}`;

  const questionValues = {
    header: `${artistName} also wants to see some images of the area of your body that you want tattooed`,
    progress: 4,
  };

  // On first component render, load images from stored values into Object URLs
  React.useEffect(() => {
    if (loading) {
      if (sessionStorage.getItem('areaImageCount')) {
        const imageCount = JSON.parse(
          sessionStorage.getItem('areaImageCount')!,
        );
        const tempObjectURLs: string[] = [];
        for (let i = 0; i < imageCount; i += 1) {
          tempObjectURLs.push(sessionStorage.getItem(`areaImage-${i}`)!);
        }
        setObjectURLs(tempObjectURLs);
        setIsValid(true);
      }
      setLoading(false);
    }
  }, [loading]);

  const handleSubmit = React.useCallback(
    (event: any) => {
      event.preventDefault();

      if (!objectURLs.length || objectURLs.length === 0) {
        setNoFiles(false);
        return;
      }

      if (!isValid) {
        return;
      }

      // Set object urls as the formValue item
      const values = {
        areaImages: objectURLs,
      };
      setFormValues({
        ...formValues,
        ...values,
      });

      try {
        // For each object URL, save it in a session variable
        objectURLs.forEach((url: string, index) => {
          sessionStorage.setItem(`areaImage-${index}`, url);
        });
        sessionStorage.setItem(
          `areaImageCount`,
          JSON.stringify(objectURLs.length),
        );

        nextStep();
      } catch {
        nextStep();
      }
    },
    [formValues, nextStep, setFormValues, objectURLs, isValid],
  );

  function printButton() {
    if (isValid) {
      return (
        <Button
          variant="contained"
          className="next done"
          onClick={handleSubmit}>
          Next
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        className="next"
        type="submit"
        onClick={handleSubmit}>
        Next
      </Button>
    );
  }

  return (
    <form>
      <Typography variant="h2" className="form-header">
        {questionValues.header}
      </Typography>
      <ImageUpload
        setIsValid={setIsValid}
        objectURLs={objectURLs}
        setObjectURLs={setObjectURLs}
        setFileSize={setFileSize}
        setFileMax={setFileMax}
      />
      {printButton()}
      {fileSize ? null : (
        <Typography variant="body1" color="primary">
          Files are too big! Max file upload size is 20MB
        </Typography>
      )}
      {fileMax ? null : (
        <Typography variant="body1" color="primary">
          Maximum of 6 images can be uploaded
        </Typography>
      )}
      {noFiles ? null : (
        <Typography variant="body1" color="primary">
          Please upload an image
        </Typography>
      )}
    </form>
  );
}

export default AreaImages;
