import { Typography } from '@mui/material';
import { DetailItem, Employee } from '../../../interfaces';
import DetailList from './DetailList';
import EmployeeHeader from '../../EmployeeHeader/EmployeeHeader';

type Props = {
  title: string;
  employee: Employee;
  detailItems: DetailItem[];
};

export default function DetailSection({ title, employee, detailItems }: Props) {
  return (
    <div className="schedule-details">
      <Typography variant="h1">{title}</Typography>
      {employee.businesses === undefined ? (
        'Loading...'
      ) : (
        <EmployeeHeader employee={employee} business={employee.businesses[0]} />
      )}
      <DetailList detailItems={detailItems} />
    </div>
  );
}
