import * as React from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { addMinutes } from 'date-fns';
import {
  confirmAppointment,
  reschedule,
  scheduleAppointment,
} from '../../../services/appointment';
import { Appointment } from '../../../interfaces';

interface Props {
  appointment: Appointment;
  authString: string;
  startDateTime: Date;
  isScheduled: boolean;
  status: string;
  liabilityWaivedOn: Date;
}

function CheckoutForm({
  appointment,
  authString,
  startDateTime,
  isScheduled,
  status,
  liabilityWaivedOn,
}: Props) {
  const stripe = useStripe();
  const elements = useElements();

  const [submitting, setSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [submitError, setSubmitError] = React.useState(false);
  const [loadingElement, setLoadingElement] = React.useState(true);

  React.useEffect(() => {
    if (elements) {
      const paymentElement = elements.create('payment');
      paymentElement.on('ready', () => {
        setLoadingElement(false);
      });
      paymentElement.mount('#payment-element');
    }
  }, [elements]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setSubmitError(true);
      setErrorMessage(
        'Please refresh the page and try again. You have not yet been charged.',
      );
      setSubmitting(false);
      return;
    }

    // If appointment ID or duration don't exist, set and display error message. Don't continue submit function
    if (!appointment.appointmentId || !appointment.appointmentLengthInMinutes) {
      setSubmitError(true);
      setErrorMessage(
        'An unexpected error occured scheduling this appointment. You have not been charged. Please try again or reach out to our support team for assistance.',
      );
      setSubmitting(false);
      return;
    }

    if (status === 'schedule') {
      if (!isScheduled) {
        // Set end date time based on start time and duration
        const endDateTime = addMinutes(
          startDateTime,
          appointment.appointmentLengthInMinutes,
        );

        // Schedule the appointment in the database
        await scheduleAppointment(
          startDateTime,
          endDateTime,
          appointment.appointmentId,
          authString,
        ).catch(() => {
          setSubmitError(true);
          setErrorMessage(
            'There was an error scheduling your appointment. Please try again or reach out to our support team for assistance. You have not been charged.',
          );
          setSubmitting(false);
        });
      }

      await stripe
        .confirmPayment({
          elements,
          confirmParams: {
            return_url: `https://${window.location.hostname}/booking/${appointment.appointmentId}`,
          },
          redirect: 'if_required',
        })
        .then(async result => {
          if (result.error) {
            setSubmitError(true);
            setSubmitting(false);
            setErrorMessage(
              result.error.message ||
                'An unexpected error occured. You have not been charged. Please try again or reach out to our support team for assistance',
            );
            return;
          }

          await confirmAppointment(
            'SUCCESS',
            '',
            appointment.appointmentId!,
            authString,
            'CONFIRMED',
            liabilityWaivedOn,
          )
            .then(() => {
              window.location.replace(`/booking/${appointment.appointmentId}`);
            })
            .catch(() => {
              setSubmitError(true);
              setSubmitting(false);
              setErrorMessage(
                'Your payment was processed, but an unexpected error occured while scheduling the appointment. Please reach out to our support team to finalize scheduling your appointment',
              );
            });
        });
    }

    if (status === 'reschedule') {
      // Set end date time based on start time and duration
      const endDateTime = addMinutes(
        startDateTime,
        appointment.appointmentLengthInMinutes,
      );

      // Reschedule the appointment
      await reschedule(
        startDateTime,
        endDateTime,
        appointment.appointmentId,
        authString,
      ).catch(() => {
        setSubmitError(true);
        setErrorMessage(
          'There was an error scheduling your appointment. Please try again or reach out to our support team for assistance. You have not been charged.',
        );
        setSubmitting(false);
      });

      await stripe
        .confirmPayment({
          elements,
          confirmParams: {
            return_url: `https://${window.location.hostname}/booking/${appointment.appointmentId}`,
          },
          redirect: 'if_required',
        })
        .then(async result => {
          if (result.error) {
            setSubmitError(true);
            setSubmitting(false);
            setErrorMessage(
              result.error.message ||
                'An unexpected error occured. You have not been charged. Please try again or reach out to our support team for assistance',
            );
          }
        });
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div id="payment-element" />
      {submitting ? (
        <div>
          <LoadingButton loading variant="contained" className="next">
            Submit
          </LoadingButton>
          <Typography variant="body1" className="loading-text">
            Confirming your appointment... Please do not refresh the page
          </Typography>
        </div>
      ) : null}

      {!submitting && stripe && !loadingElement ? (
        <Button
          variant="contained"
          className="next done"
          id="submit"
          type="submit">
          Confirm &amp; Pay
        </Button>
      ) : null}

      {submitError ? (
        <Typography variant="body1" className="error">
          {errorMessage}
        </Typography>
      ) : null}
    </form>
  );
}

export default CheckoutForm;
