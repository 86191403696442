import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { Employee, IntakeQuestion } from '../../interfaces';
import './ArtistHeader.css';

interface Props {
  progress: string;
  employee: Employee;
}

function ProgressBar({ progress, employee }: Props) {
  employee.intakeQuestions!.sort((a: any, b: any) => a.step - b.step);
  const sections: String[] = [];

  sections.push('Artist');

  employee.intakeQuestions!.forEach((question: IntakeQuestion) => {
    if (!sections.includes(question.category)) {
      sections.push(question.category);
    }
  });

  const progNum = sections.indexOf(progress) + 1;
  const gridSize = 12 / sections.length;
  let lineBreak = progNum - 0.5;

  if (progNum === sections.length) {
    lineBreak += 0.5;
  }

  lineBreak = (lineBreak / sections.length) * 100;

  const lineStyle = {
    background: `linear-gradient(to right, #FF3008 0%, #FF3008 ${lineBreak}%, #BDBDBD ${lineBreak}%, #BDBDBD 100%`,
  };

  return (
    <div className="progress-bar">
      <Grid container spacing={0}>
        {sections.map((section, index) => (
          <Grid xs={gridSize} className="step">
            <div className={`step-circle ${progNum > index ? 'done' : ''}`}>
              <Typography variant="h2">{index + 1}</Typography>
            </div>
            <div className="step-label">
              <Typography variant="body1">{section}</Typography>
            </div>
          </Grid>
        ))}
      </Grid>
      <div className="progress-line" style={lineStyle} />
    </div>
  );
}

export default ProgressBar;
