import axios from 'axios';
import API_URL from '../apiconfig';
import { Appointment, User, FormValues } from '../interfaces';

export async function createUpdateCustomer(
  formValues: FormValues,
  user: User,
  authorization: string,
) {
  try {
    const customerPayload = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      dob: formValues.birthday,
    };

    const customerResponse = await axios.patch(
      `${API_URL}/v1/customers/${user.userId}`,
      customerPayload,
      {
        headers: {
          authorization,
        },
      },
    );

    return customerResponse;
  } catch (error) {
    throw new Error('Cannot update customer');
  }
}

export async function getCustomersAppointments(authorization: string) {
  try {
    const appointments = await axios.get(
      `${API_URL}/v1/appointments?limit=25`,
      {
        headers: {
          authorization,
        },
      },
    );

    return appointments.data.result;
  } catch (error) {
    throw new Error('Cannot get customers appointments');
  }
}

export function hasOpenAppointment(
  appointments: Appointment[],
  employeeId: string,
) {
  let openAppointment = false;
  let appointmentId = '';

  if (appointments.length > 0) {
    appointments.forEach(appointment => {
      const appointmentEmployeeId =
        appointment.employeeId || appointment.employee?.userId;
      if (
        appointmentEmployeeId === employeeId &&
        (appointment.status === 'REQUESTED' ||
          appointment.status === 'ACCEPTED' ||
          appointment.status === 'REJECTED')
      ) {
        openAppointment = true;
        appointmentId = appointment.appointmentId!;
      }
    });
  }

  if (openAppointment) {
    return appointmentId;
  }

  return openAppointment;
}
