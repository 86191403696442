import * as React from 'react';
import { format, isSameMonth } from 'date-fns';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { DayItem } from '../../../../interfaces';

type Props = {
  day: DayItem;
  selectedMonth: Date;
  setSelectedDay: React.Dispatch<React.SetStateAction<Date>>;
  setDayIsSelected: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Day({
  day,
  selectedMonth,
  setSelectedDay,
  setDayIsSelected,
}: Props) {
  let date: Date;

  if (day.date.length === 10) {
    date = new Date(`${day.date}T00:00:00`);
  } else {
    date = new Date(day.date);
  }

  const sameMonth = isSameMonth(date, selectedMonth);
  const dayFormat = 'd';

  function setDay(selectedDay: Date) {
    setSelectedDay(selectedDay);
    setDayIsSelected(true);
  }

  switch (true) {
    case !sameMonth:
      return (
        <Grid item xs={1} className="day hidden">
          <Typography variant="body1">{format(date, dayFormat)}</Typography>
        </Grid>
      );

    case !day.available:
      return (
        <Grid item xs={1} className="day">
          <Typography variant="body1">{format(date, dayFormat)}</Typography>
        </Grid>
      );

    case day.available:
      return (
        <Grid
          item
          xs={1}
          className="day available"
          onClick={() => setDay(date)}>
          <Typography variant="body1">{format(date, dayFormat)}</Typography>
        </Grid>
      );

    default:
      return (
        <Grid item xs={1} className="day available">
          <Typography variant="body1">{format(date, dayFormat)}</Typography>
        </Grid>
      );
  }
}
