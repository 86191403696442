import * as React from 'react';
import './Booking.css';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { Employee, Appointment } from '../../interfaces';
import DetailHeader from './Appointment/DetailHeader';
import { createReviewItems } from '../../utils/appointment';
import DetailSection from './Appointment/DetailSection';
import Waiver from './Confirm/Waiver';
import PaymentSection from './Confirm/PaymentSection';

type Props = {
  appointment: Appointment;
  employee: Employee;
  selectedDateTime: Date;
  authString: string;
  status: string;
};

function Confirm({
  appointment,
  employee,
  selectedDateTime,
  authString,
  status,
}: Props) {
  const [waiver, setWaiver] = React.useState(false);
  const [liabilityWaivedOn, setLiabilityWaivedOn] = React.useState<Date>(
    new Date(),
  );

  let title = '';
  const name = employee.displayName
    ? employee.displayName
    : `${employee.user.firstName} ${employee.user.lastName}`;

  if (status === 'reschedule') {
    title = `Reschedule Your Appointment with ${name}`;
  } else {
    title = `Review & Confirm Your Appointment with ${name}`;
  }

  const detailItems = createReviewItems(
    selectedDateTime,
    appointment,
    employee,
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [waiver]);

  return (
    <Paper className="review-container confirm">
      <Container maxWidth="lg">
        <Grid container spacing={0}>
          <Grid xs={12} md={6} className="review-left">
            <div className="appointment-details">
              <DetailHeader appointment={appointment} />
              <DetailSection
                title={title}
                employee={employee}
                detailItems={detailItems}
              />
            </div>
          </Grid>
          {waiver ? (
            <Grid xs={12} md={6} className="payment-details">
              <PaymentSection
                appointment={appointment}
                authString={authString}
                startDateTime={selectedDateTime}
                status={status}
                employee={employee}
                liabilityWaivedOn={liabilityWaivedOn}
              />
            </Grid>
          ) : (
            <Grid xs={12} md={6} className="payment-details">
              <Waiver
                name={name}
                employee={employee}
                setWaiver={setWaiver}
                setLiabilityWaivedOn={setLiabilityWaivedOn}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Paper>
  );
}

export default Confirm;
