import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Modal from './Modal';
import { Employee } from '../../interfaces';

type Props = {
  employees: Employee[];
};

export default function EmployeeGrid({ employees }: Props) {
  return (
    <Grid container spacing={2}>
      {employees.map(employee => (
        <Grid sm={12} md={3} className="artist-card" key={employee.urlPath}>
          <div>
            <Link href={`/artist/${employee.urlPath}`}>
              <img
                className="artist-image"
                src={employee.profileImage}
                alt="Artist profile"
              />
            </Link>
            <Typography variant="h3">
              {employee.displayName
                ? employee.displayName
                : `${employee.user.firstName} ${employee.user.lastName}`}
            </Typography>
            <Modal employee={employee} />
          </div>
        </Grid>
      ))}
    </Grid>
  );
}
