import * as React from 'react';
import { format, addMonths, subMonths } from 'date-fns';
import Typography from '@mui/material/Typography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

type Props = {
  selectedMonth: Date;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMonth: React.Dispatch<React.SetStateAction<Date>>;
};

export default function CalendarNav({
  selectedMonth,
  setLoading,
  setSelectedMonth,
}: Props) {
  const prevMonth = React.useCallback(() => {
    setSelectedMonth(subMonths(selectedMonth, 1));
    setLoading(true);
  }, [selectedMonth, setSelectedMonth, setLoading]);

  const nextMonth = React.useCallback(() => {
    setSelectedMonth(addMonths(selectedMonth, 1));
    setLoading(true);
  }, [selectedMonth, setSelectedMonth, setLoading]);

  const monthFormat = 'MMMM Y';

  return (
    <div className="calendar-header">
      <Typography variant="body1">
        {format(selectedMonth, monthFormat)}
      </Typography>
      <div className="calendar-toggles">
        <ArrowBackIosIcon onClick={prevMonth} />
        <ArrowForwardIosIcon onClick={nextMonth} color="primary" />
      </div>
    </div>
  );
}
