import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function CalendarHeader() {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return (
    <div className="calendar-days">
      <Grid container spacing={0} columns={7}>
        {days.map(day => (
          <Grid item xs={1} className="day-of-week" key={day}>
            <Typography variant="h4">{day}</Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
