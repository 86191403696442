import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';
import './Form.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { FormValues, Choice } from '../../interfaces';
import { getChoices } from '../../services/choices';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
  authString: string;
};

const validationSchema = yup.object({
  color: yup.string().required('Required'),
});

function Color({ formValues, setFormValues, nextStep, authString }: Props) {
  const [colors, setColors] = React.useState<Choice[]>();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    async function fetchData() {
      const tempColors = await getChoices('TATTOO_COLOR', authString).catch(
        () => {},
      );
      tempColors.sort((a: Choice, b: Choice) => {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        if (textA < textB) {
          return -1;
        }
        if (textA > textB) {
          return 1;
        }
        return 0;
      });
      setColors(tempColors);
      setLoading(false);
    }
    if (loading) {
      fetchData();
    }
  });

  const questionValues = {
    header: 'What coloring do you want for your tattoo?',
    progress: 2,
  };

  const formik = useFormik({
    initialValues: {
      color: formValues.color,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('color', values.color!);
      nextStep();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h2" className="form-header">
        {questionValues.header}
      </Typography>
      <RadioGroup
        aria-labelledby="radio-color"
        name="color"
        value={formik.values.color}
        onChange={formik.handleChange}
        className="radio-box">
        {loading ? 'Loading...' : null}
        {!loading && !colors ? 'Error loading tattoo color options' : null}
        {colors && colors.length > 0
          ? colors.map((color: Choice) => (
              <FormControlLabel
                value={color.name}
                key={color.name}
                control={<Radio className="radio-input" />}
                label={color.name}
                className={`radio-pill ${
                  formik.values.color === color.name ? 'checked' : ''
                }`}
              />
            ))
          : null}
      </RadioGroup>
      {formik.touched.color && formik.errors.color && (
        <FormHelperText>{formik.errors.color}</FormHelperText>
      )}
      <Button
        variant="contained"
        className={`next ${formik.isValid ? 'done' : ''}`}
        type="submit">
        Next
      </Button>
    </form>
  );
}

export default Color;
