import Link from '@mui/material/Link';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Appointment } from '../../../interfaces';

interface Props {
  appointment: Appointment;
}

export default function DetailHeader({ appointment }: Props) {
  return (
    <div className="back" color="white">
      <Link href={`/booking/${appointment.appointmentId}`}>
        <ArrowBackIcon fontSize="small" />
      </Link>
    </div>
  );
}
