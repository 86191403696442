import { format, addMinutes } from 'date-fns';
import { Appointment, DetailItem, Employee, User } from '../interfaces';

export function getPriceText(
  priceType: string,
  min?: number,
  max?: number,
  hourly?: number,
  set?: number,
) {
  let text = '';

  switch (priceType) {
    case 'RANGE':
      text = `$${min?.toLocaleString('en-US')} - $${max?.toLocaleString(
        'en-US',
      )} price range`;
      break;

    case 'HOURLY':
      text = `$${hourly?.toLocaleString('en-US')} per hour`;
      break;

    case 'FIXED':
      text = `$${set?.toLocaleString('en-US')}`;
      break;

    default:
      break;
  }

  return text;
}

function getLengthText(aptLength: number) {
  const hours = Math.floor(aptLength / 60);
  const minutes = aptLength % 60;

  let text = '';
  if (minutes === 0) {
    text = `${hours} hour appointment`;
  } else {
    text = `${hours} hour and ${minutes} minute appointment`;
  }
  return text;
}

function getAptStartEnd(date: Date, aptLength: number) {
  const startDateFormat = 'eeee, MMMM d, yyyy';
  const startTimeFormat = 'h:mma';
  const endTimeFormat = 'h:mma';
  const endTime = addMinutes(date, aptLength);

  const text = `${format(date, startDateFormat)} at ${format(
    date,
    startTimeFormat,
  )}. Estimated to end at ${format(endTime, endTimeFormat)}`;
  return text;
}

export function createDetailitems(
  aptDetails: Appointment,
  employee: Employee,
  aptDate?: Date,
) {
  const tempDetailItems: DetailItem[] = [];

  if (aptDate && aptDetails.appointmentLengthInMinutes) {
    tempDetailItems.push({
      label: 'When',
      values: [getAptStartEnd(aptDate, aptDetails.appointmentLengthInMinutes)],
      type: 'text',
    });
  }

  if (aptDetails.appointmentLengthInMinutes) {
    tempDetailItems.push({
      label: 'Appointment Length',
      values: [getLengthText(aptDetails.appointmentLengthInMinutes)],
      type: 'text',
    });
  }

  if (aptDetails.tattooLocation) {
    tempDetailItems.push({
      label: 'Tattoo Area',
      values: [aptDetails.tattooLocation],
      type: 'text',
    });
  }

  if (aptDetails.tattooSize) {
    tempDetailItems.push({
      label: 'Tattoo Size',
      values: [aptDetails.tattooSize],
      type: 'text',
    });
  }

  if (aptDetails.tattooColor) {
    tempDetailItems.push({
      label: 'Tattoo Color',
      values: [aptDetails.tattooColor],
      type: 'text',
    });
  }

  if (aptDetails.tattooDetails) {
    tempDetailItems.push({
      label: 'Tattoo Description',
      values: [aptDetails.tattooDetails],
      type: 'text',
    });
  }

  if (aptDetails.priceType) {
    tempDetailItems.push({
      label: 'Price',
      values: [
        getPriceText(
          aptDetails.priceType,
          aptDetails.minPrice,
          aptDetails.maxPrice,
          aptDetails.hourlyPrice,
          aptDetails.price,
        ),
      ],
      type: 'text',
    });
  }

  if (employee.cancellationPolicyText) {
    tempDetailItems.push({
      label: 'Cancellation Policy',
      values: [employee.cancellationPolicyText],
      type: 'text',
    });
  }

  return tempDetailItems;
}

export function createReviewItems(
  selectedDateTime: Date,
  appointment: Appointment,
  employee: Employee,
) {
  const dateFormat = 'MMMM d, Y @ h:mmaaa';
  const tempDetailItems: DetailItem[] = [];

  tempDetailItems.push({
    label: 'When',
    values: [format(selectedDateTime, dateFormat)],
    type: 'text',
  });

  if (appointment.appointmentLengthInMinutes) {
    tempDetailItems.push({
      label: 'Appointment Length',
      values: [getLengthText(appointment.appointmentLengthInMinutes)],
      type: 'text',
    });
  }

  if (appointment.customDesignMessage) {
    tempDetailItems.push({
      label: 'Designs',
      values: [appointment.customDesignMessage],
      type: 'text',
    });
  } else {
    tempDetailItems.push({
      label: 'Designs',
      values: [employee.standardDesignMessage],
      type: 'text',
    });
  }

  tempDetailItems.push({
    label: 'Cancellation Policy',
    values: [employee.cancellationPolicyText],
    type: 'text',
  });

  return tempDetailItems;
}

export function createRequestOverview(user: User, appointment: Appointment) {
  const tempDetailItems: DetailItem[] = [];

  if (appointment.rejectionMessage) {
    tempDetailItems.push({
      label: 'Reason for rejection',
      values: [appointment.rejectionMessage],
      type: 'text',
    });
  }

  if (user.firstName) {
    tempDetailItems.push({
      label: 'Your Name',
      values: [`${user.firstName} ${user.lastName}`],
      type: 'text',
    });
  }

  if (user.email) {
    tempDetailItems.push({
      label: 'Email',
      values: [user.email],
      type: 'text',
    });
  }

  if (user.phoneNumber) {
    tempDetailItems.push({
      label: 'Phone Number',
      values: [user.phoneNumber],
      type: 'text',
    });
  }

  if (appointment.tattooLocation) {
    tempDetailItems.push({
      label: 'Tattoo Area',
      values: [appointment.tattooLocation],
      type: 'text',
    });
  }

  if (appointment.tattooSize) {
    tempDetailItems.push({
      label: 'Tattoo Size',
      values: [appointment.tattooSize],
      type: 'text',
    });
  }

  if (appointment.tattooColor) {
    tempDetailItems.push({
      label: 'Tattoo Color',
      values: [appointment.tattooColor],
      type: 'text',
    });
  }

  if (appointment.tattooDetails) {
    tempDetailItems.push({
      label: 'Tattoo Description',
      values: [appointment.tattooDetails],
      type: 'text',
    });
  }

  if (appointment.tattooInspiration) {
    tempDetailItems.push({
      label: 'Inspiration',
      values: [appointment.tattooInspiration],
      type: 'text',
    });
  }

  if (appointment.prefTattooStyle) {
    tempDetailItems.push({
      label: 'Preferred Tattoo Styles',
      values: [appointment.prefTattooStyle],
      type: 'text',
    });
  }

  if (appointment.budget) {
    tempDetailItems.push({
      label: 'Budget',
      values: [`$${appointment.budget}`],
      type: 'text',
    });
  }

  if (appointment.skinTone) {
    tempDetailItems.push({
      label: 'Skin Tone',
      values: [appointment.skinTone],
      type: 'text',
    });
  }

  if (appointment.refImgsId) {
    tempDetailItems.push({
      label: 'Uploaded Photos',
      values: appointment.refImgsId,
      type: 'image',
    });
  }

  if (appointment.areaImgsId) {
    tempDetailItems.push({
      label: 'Uploaded Photos',
      values: appointment.areaImgsId,
      type: 'image',
    });
  }

  return tempDetailItems;
}
