import axios from 'axios';
import API_URL from '../apiconfig';

export async function getChoices(category: string, authorization: string) {
  try {
    const choicesResult = await axios.get(`${API_URL}/v1/choices/tattoo`, {
      headers: {
        authorization,
      },
      params: {
        category,
      },
    });

    return choicesResult.data.result;
  } catch (error) {
    throw new Error('Cannot get tattoo choices');
  }
}
