import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ShopPage from './routes/shop';
import ArtistPage from './routes/artist';
import BookingPage from './routes/booking';
import ReschedulePage from './routes/reschedule';

const root = createRoot(document.getElementById('root') as HTMLElement);

const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'Open Sans',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      light: '#ff6c3c',
      main: '#ff3008',
      dark: '#c30000',
      contrastText: '#fff',
    },
  },
});

theme.typography.h1 = {
  fontSize: '1.625rem',
  fontWeight: '600',
  textAlign: 'left',
};

theme.typography.h2 = {
  fontSize: '1.375rem',
  fontWeight: '600',
  textAlign: 'left',
  color: '#333333',
};

theme.typography.h3 = {
  fontSize: '1.125rem',
  fontWeight: '600',
  textAlign: 'left',
  color: '#333333',
};

theme.typography.button = {
  fontSize: '1.125rem',
  fontWeight: '600',
  lineHeight: 'auto',
};

theme.typography.h4 = {
  fontSize: '.75rem',
  fontWeight: '600',
  textAlign: 'left',
  color: '#333333',
};

theme.typography.body1 = {
  fontSize: '1.125rem',
  fontWeight: '400',
  textAlign: 'left',
  color: '#333333',
};

theme.typography.body2 = {
  fontSize: '.75rem',
  fontWeight: '400',
  textAlign: 'left',
  color: '#333333',
};

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="artist/:urlPath" element={<ArtistPage />} />
            <Route path="shop/:urlPath" element={<ShopPage />} />
            <Route path="booking/:appointmentId" element={<BookingPage />} />
            <Route
              path="reschedule/:appointmentId"
              element={<ReschedulePage />}
            />
            <Route
              path="*"
              element={
                <main style={{ padding: '1rem' }}>
                  <p>There&apos;s nothing here!</p>
                </main>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
