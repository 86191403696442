import * as React from 'react';
import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  format,
} from 'date-fns';
import Grid from '@mui/material/Grid';
import { DayItem } from '../../../interfaces';
import Month from './Days/Month';
import CalendarHeader from './CalendarHeader';
import CalendarNav from './CalendarNav';
import TimeList from './Times/TimeList';
import { getAvailableDays } from '../../../services/availability';

type Props = {
  duration: number;
  employeeId: string;
  authString: string;
  selectedDay: Date;
  dayIsSelected: boolean;
  setSelectedDay: React.Dispatch<React.SetStateAction<Date>>;
  setDayIsSelected: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedDateTime: React.Dispatch<React.SetStateAction<Date>>;
  setDateTimeIsSelected: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Calendar({
  duration,
  employeeId,
  authString,
  selectedDay,
  dayIsSelected,
  setSelectedDay,
  setDayIsSelected,
  setSelectedDateTime,
  setDateTimeIsSelected,
}: Props) {
  const [availableDays, setAvailableDays] = React.useState<DayItem[]>();
  const [selectedMonth, setSelectedMonth] = React.useState(new Date());
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      // Set start and end dates to display on the calendar
      const firstOfMonth = startOfMonth(selectedMonth);
      const lastOfMonth = endOfMonth(selectedMonth);
      const calStart = startOfWeek(firstOfMonth);
      const calEnd = endOfWeek(lastOfMonth);

      // Set available days based on onsched API call
      const tempAvailableDays = await getAvailableDays(
        format(calStart, 'yyyy-MM-dd'),
        format(calEnd, 'yyyy-MM-dd'),
        duration,
        employeeId,
        authString,
      ).catch(() => setError(true));
      setAvailableDays(tempAvailableDays.availableDays);

      setLoading(false);
    }
    if (loading) {
      fetchData();
    }
  }, [selectedMonth, loading, authString, duration, employeeId]);

  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={12}
        sm={dayIsSelected ? 8 : 12}
        className={`calendar-box ${dayIsSelected ? 'hidden' : ''}`}>
        <CalendarNav
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
          setLoading={setLoading}
        />
        <CalendarHeader />
        {!loading && availableDays ? (
          <Month
            dayItems={availableDays}
            selectedMonth={selectedMonth}
            setSelectedDay={setSelectedDay}
            setDayIsSelected={setDayIsSelected}
          />
        ) : (
          'Loading...'
        )}
        {error ? 'Error loading calendar for this artist' : null}
      </Grid>
      {dayIsSelected ? (
        <TimeList
          selectedDay={selectedDay}
          duration={duration}
          employeeId={employeeId}
          authString={authString}
          setSelectedDateTime={setSelectedDateTime}
          setDateTimeIsSelected={setDateTimeIsSelected}
        />
      ) : null}
    </Grid>
  );
}
