import axios from 'axios';
import API_URL from '../apiconfig';

export async function getAvailableDays(
  startDate: string,
  endDate: string,
  duration: number,
  employeeId: string,
  authorization: string,
) {
  try {
    const response = await axios.get(`${API_URL}/v1/availability`, {
      headers: {
        authorization,
      },
      params: {
        by: 'days',
        startDate,
        endDate,
        duration,
        employeeId,
      },
    });

    return response.data.result;
  } catch (error) {
    throw new Error('Cannot get available days');
  }
}

export async function getAvailableTimes(
  startDate: string,
  endDate: string,
  duration: number,
  employeeId: string,
  authorization: string,
) {
  try {
    const response = await axios.get(`${API_URL}/v1/availability`, {
      headers: {
        authorization,
      },
      params: {
        by: 'time',
        startDate,
        endDate,
        duration,
        employeeId,
      },
    });

    return response.data.result;
  } catch (error) {
    throw new Error('Cannot get available times');
  }
}
