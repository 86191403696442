import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AuthCode from 'react-auth-code-input';

interface LoginValuesModel {
  step: number;
  userPhone: string;
  code: String;
  loggedIn: Boolean;
  confirmationResult?: any;
}

type Props = {
  loginValues: LoginValuesModel;
  prevStep: () => void;
};

function Code({ loginValues, prevStep }: Props) {
  const [result, setResult] = React.useState('');
  const [valid, setValid] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleOnChange = (res: string) => {
    setResult(res);
    if (res.length === 6) {
      setValid(true);
    }
  };

  const header = "What's the code?";

  const authenticate = React.useCallback(async () => {
    loginValues.confirmationResult.confirm(result).catch(() => {
      setError(true);
    });
  }, [result, loginValues.confirmationResult]);

  const errorMessage = (
    <Typography variant="h3" color="primary" className="error">
      Code could not be verified. Please try again
    </Typography>
  );

  return (
    <form>
      <div className="back" color="white">
        <ArrowBackIcon fontSize="small" onClick={() => prevStep()} />
      </div>
      <Typography variant="h2" className="form-header sub">
        {header}
      </Typography>
      <Typography variant="body1" className="sub">
        Enter the code sent to <strong>{loginValues.userPhone}</strong>
      </Typography>
      <AuthCode
        allowedCharacters="numeric"
        inputClassName="input-code"
        onChange={handleOnChange}
      />
      <Button
        variant="contained"
        className={`next ${valid ? 'done' : ''}`}
        onClick={authenticate}>
        Let&apos;s Do This!
      </Button>
      <Typography variant="body2" className="tos">
        By creating your account you acknowledge that you have read, understood,
        and agree to our{' '}
        <Link href="https://getporter.io/terms-of-us" target="_blank">
          Terms of Use
        </Link>
        .
      </Typography>
      {error ? errorMessage : null}
    </form>
  );
}

export default Code;
