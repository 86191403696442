import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig: FirebaseOptions = {
  apiKey:
    process.env.FIREBASE_API_KEY || 'AIzaSyCZM0XQuH0X5JxILw0hxR8zOtLUtYfadYA',
  authDomain:
    process.env.FIREBASE_AUTH_DOMAIN || 'getinked-dev.firebaseapp.com',
  projectId: process.env.FIREBASE_PROJET_ID || 'getinked-dev',
  storageBucket:
    process.env.FIREBASE_STORAGE_BUCKET || 'getinked-dev.appspot.com',
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID || '770416014343',
  appId:
    process.env.FIREBASE_APP_ID || '1:770416014343:web:53ace47045252845e926a2',
  measurementId: process.env.MEASUREMENT_ID || '',
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
