import axios from 'axios';
import API_URL from '../apiconfig';

export async function getUser(authorization: string) {
  try {
    const user = await axios.get(`${API_URL}/v1/users/me`, {
      headers: {
        authorization,
      },
    });

    return user.data.result;
  } catch (error) {
    throw new Error('Cannot get user');
  }
}
