import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Employee, Appointment, DetailItem } from '../../interfaces';
import Calendar from './Scheduler/Calendar';
import './Booking.css';
import DetailSection from './Appointment/DetailSection';
import { createDetailitems } from '../../utils/appointment';

type Props = {
  appointment: Appointment;
  employee: Employee;
  authString: string;
  status: string;
  setSelectedDateTime: React.Dispatch<React.SetStateAction<Date>>;
  setDateTimeIsSelected: React.Dispatch<React.SetStateAction<boolean>>;
};

function Schedule({
  appointment,
  employee,
  authString,
  status,
  setSelectedDateTime,
  setDateTimeIsSelected,
}: Props) {
  const [selectedDay, setSelectedDay] = React.useState(new Date());
  const [dayIsSelected, setDayIsSelected] = React.useState(false);
  const [detailItems, setDetailItems] = React.useState<DetailItem[]>();
  const [loading, setLoading] = React.useState(true);
  let title = 'Schedule & Confirm';

  if (status === 'reschedule') {
    title = 'Reschedule Appointment';
  }

  React.useEffect(() => {
    async function fetchData() {
      // Set detail items based on appointment details
      setDetailItems(createDetailitems(appointment, employee));
      setLoading(false);
    }
    if (loading) {
      fetchData();
    }
  }, [appointment, employee, loading]);

  return (
    <Container maxWidth="lg">
      <Grid
        container
        spacing={0}
        className={`schedule-grid ${dayIsSelected ? 'selected' : ''}`}
        columns={dayIsSelected ? 15 : 12}>
        <Grid
          item
          xs={dayIsSelected ? 15 : 12}
          md={6}
          className="schedule-grid-item detail-section">
          {detailItems ? (
            <DetailSection
              title={title}
              employee={employee}
              detailItems={detailItems}
            />
          ) : null}
        </Grid>
        <Grid
          item
          xs={dayIsSelected ? 15 : 12}
          md={dayIsSelected ? 9 : 6}
          className="schedule-grid-item">
          <div className="schedule-box">
            {dayIsSelected ? (
              <div className="back" color="white">
                <ArrowBackIcon
                  fontSize="small"
                  onClick={() => setDayIsSelected(false)}
                />
              </div>
            ) : null}
            <Typography variant="h3">Select date &amp; time</Typography>
            {appointment.appointmentLengthInMinutes ? (
              <Calendar
                duration={appointment.appointmentLengthInMinutes}
                employeeId={employee.employeeId}
                authString={authString}
                selectedDay={selectedDay}
                dayIsSelected={dayIsSelected}
                setSelectedDay={setSelectedDay}
                setDayIsSelected={setDayIsSelected}
                setSelectedDateTime={setSelectedDateTime}
                setDateTimeIsSelected={setDateTimeIsSelected}
              />
            ) : (
              <Typography variant="body1">
                Error loading calendar for this appointment. Please reach out to
                our support team through the help icon below.
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Schedule;
