import { Typography } from '@mui/material';

interface Props {
  label: string;
  amount: string;
}

export default function PaymentTotalRow({ label, amount }: Props) {
  return (
    <div className="payment-row payment-total">
      <Typography variant="h3">{label}</Typography>
      <Typography variant="body1">{amount}</Typography>
    </div>
  );
}
