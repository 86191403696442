import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Employee } from '../../interfaces';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type Props = {
  employee: Employee;
};

export default function BasicModal({ employee }: Props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const name = employee.displayName
    ? employee.displayName
    : `${employee.user.firstName} ${employee.user.lastName}`;

  return (
    <div>
      <Button onClick={handleOpen}>About {name}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            About {name}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {employee.about}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
