import * as React from 'react';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Business } from '../interfaces';
import { auth } from '../firebase';
import { setAuthorization } from '../services/auth';
import Login from '../components/auth/Login';
import BusinessHeader from '../components/BusinessHeader/BusinessHeader';
import { getBusinessByUrlPath } from '../services/business';
import EmployeeGrid from '../components/BusinessHeader/EmployeeGrid';

export default function ShopPage() {
  const [business, setBusiness] = React.useState<Business>();
  const { urlPath } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [doneLoading, setDoneLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [scroll, setScroll] = React.useState(false);
  const [gridScroll, setGridScroll] = React.useState(false);
  const [authUser, authLoading] = useAuthState(auth);

  React.useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 225) {
        setScroll(true);
        setGridScroll(true);
      } else {
        setGridScroll(false);
      }
    });
  }, []);

  React.useEffect(() => {
    async function fetchData() {
      if (authUser) {
        setAuthorization(authUser).then(async authorization => {
          if (authorization) {
            try {
              const tempShop = await getBusinessByUrlPath(
                urlPath,
                authorization,
              ).catch(() => {
                setError(true);
              });

              if (tempShop) {
                setBusiness({
                  ...tempShop,
                });
              }
            } catch {
              setError(true);
            }
          }
        });
        setDoneLoading(true);
      }
      setLoading(false);
    }

    if (loading || (authUser && !doneLoading)) {
      fetchData();
    }
  }, [authUser, loading, doneLoading, urlPath]);

  switch (true) {
    // Everything is loading
    case loading || authLoading:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // User authed, everything else is loading
    case authUser && !business && !error:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // User is unauthenticated
    case !authLoading && !authUser:
      return (
        <Container maxWidth="lg" className="login">
          <Login />
        </Container>
      );

    // Everything is loaded
    case authUser && business !== undefined:
      return (
        <div className="shop-page">
          <BusinessHeader business={business!} />
          <Container maxWidth="lg">
            <div
              className={`shop-grid ${scroll && gridScroll ? 'scroll' : ''} ${
                scroll && !gridScroll ? 'final' : ''
              }`}>
              {business!.employees ? (
                <EmployeeGrid employees={business!.employees} />
              ) : (
                'Loading...'
              )}
            </div>
          </Container>
        </div>
      );

    default:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="h1">
            There was an error loading this shop. Please double check that
            you&apos;ve entered the URL in correctly, and if the error persists,
            reach out to our support team using the help icon below.
          </Typography>
        </Container>
      );
  }
}
