import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';
import './Form.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { FormValues } from '../../interfaces';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
};

const validationSchema = yup.object({
  firstTattoo: yup.string().required('Required'),
});

function FirstTattoo({ formValues, setFormValues, nextStep }: Props) {
  const questionValues = {
    header: 'Is this your first tattoo?',
    progress: 5,
  };

  const formik = useFormik({
    initialValues: {
      firstTattoo: formValues.firstTattoo,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('firstTattoo', values.firstTattoo!);
      nextStep();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h2" className="form-header">
        {questionValues.header}
      </Typography>
      <RadioGroup
        aria-labelledby="radio-firstTattoo"
        name="firstTattoo"
        value={formik.values.firstTattoo}
        onChange={formik.handleChange}
        className="radio-box">
        <FormControlLabel
          value="true"
          control={<Radio className="radio-input" />}
          label="Yes"
          className={`radio-pill ${
            formik.values.firstTattoo === 'true' ? 'checked' : ''
          }`}
        />
        <FormControlLabel
          value="false"
          control={<Radio className="radio-input" />}
          label="No"
          className={`radio-pill ${
            formik.values.firstTattoo === 'false' ? 'checked' : ''
          }`}
        />
      </RadioGroup>
      {formik.touched.firstTattoo && formik.errors.firstTattoo && (
        <FormHelperText>{formik.errors.firstTattoo}</FormHelperText>
      )}
      <Button
        variant="contained"
        className={`next ${formik.isValid ? 'done' : ''}`}
        type="submit">
        Next
      </Button>
    </form>
  );
}

export default FirstTattoo;
