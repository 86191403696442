import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormValues } from '../../interfaces';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
};

const validationSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
});

function Name({ formValues, setFormValues, nextStep }: Props) {
  const questionValues = {
    header: "Let's book your tattoo!",
    progress: 2,
  };

  const formik = useFormik({
    initialValues: {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('firstName', values.firstName!);
      sessionStorage.setItem('lastName', values.lastName!);
      nextStep();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h2" className="form-header">
        {questionValues.header}
      </Typography>
      <div className="name-input-container">
        <TextField
          id="firstName"
          name="firstName"
          label="First Name"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
        <TextField
          id="lastName"
          name="lastName"
          label="Last Name"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
      </div>
      <Button
        variant="contained"
        className={`next ${formik.isValid ? 'done' : ''}`}
        type="submit">
        Let&apos;s Do This!
      </Button>
    </form>
  );
}

export default Name;
