import { User } from 'firebase/auth';

export async function setAuthorization(authUser: User | null | undefined) {
  if (authUser) {
    const accessToken = await authUser.getIdToken();
    // console.log(accessToken);
    return `Bearer ${accessToken}`;
  }
  return false;
}
