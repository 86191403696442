import axios from 'axios';
import API_URL from '../apiconfig';

export async function getIntakeQuestion(
  questionId: string,
  authorization: string,
) {
  try {
    const intakeQuestion = await axios.get(
      `${API_URL}/v1/intake-questions/${questionId}`,
      {
        headers: {
          authorization,
        },
      },
    );

    return intakeQuestion.data.result;
  } catch (error) {
    throw new Error('Cannot get Intake Question');
  }
}
