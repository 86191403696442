import * as React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';
import MenuItem from '@mui/material/MenuItem';
import { Choice, FormValues } from '../../interfaces';
import { getChoices } from '../../services/choices';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
  authString: string;
};

const validationSchema = yup.object({
  location: yup.string().required('Please select a tatoo location'),
});

function TattooLocation({
  formValues,
  setFormValues,
  nextStep,
  authString,
}: Props) {
  const [locations, setLocations] = React.useState<Choice[]>();
  const [loading, setLoading] = React.useState(true);

  const questionValues = {
    header: `Hi ${formValues.firstName}! Where on your body do you want your tattoo?`,
    progress: 2,
  };

  React.useEffect(() => {
    async function fetchData() {
      const tempLocations = await getChoices('PLACEMENT', authString).catch(
        () => {},
      );
      tempLocations.sort((a: Choice, b: Choice) => {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        if (textA < textB) {
          return -1;
        }
        if (textA > textB) {
          return 1;
        }
        return 0;
      });
      setLocations(tempLocations);
      setLoading(false);
    }
    if (loading) {
      fetchData();
    }
  });

  const formik = useFormik({
    initialValues: {
      location: formValues.location,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('location', values.location!);
      nextStep();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h2" className="form-header">
        {questionValues.header}
      </Typography>
      {loading ? 'Loading...' : null}
      {!loading && !locations ? 'Error loading tattoo locations' : null}
      {locations && locations.length > 0 ? (
        <TextField
          id="location"
          select
          name="location"
          label="Tattoo Location"
          value={formik.values.location}
          onChange={formik.handleChange}
          error={formik.touched.location && Boolean(formik.errors.location)}
          helperText={formik.touched.location && formik.errors.location}>
          {locations.map(location => (
            <MenuItem key={location.name} value={location.name}>
              {location.name}
            </MenuItem>
          ))}
        </TextField>
      ) : null}
      <Button
        variant="contained"
        className={`next ${formik.isValid ? 'done' : ''}`}
        type="submit">
        Next
      </Button>
    </form>
  );
}

export default TattooLocation;
