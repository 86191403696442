import * as React from 'react';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Employee, User } from '../interfaces';
import EmployeeHeader from '../components/EmployeeHeader/EmployeeHeader';
import ProgressBar from '../components/EmployeeHeader/ProgressBar';
import Form from '../components/Form/Form';
import Login from '../components/auth/Login';
import { auth } from '../firebase';
import { setAuthorization } from '../services/auth';
import { getUser } from '../services/user';
import { getEmployeeByUrlPath } from '../services/employee';
import {
  getCustomersAppointments,
  hasOpenAppointment,
} from '../services/customer';

export default function ArtistPage() {
  const [employee, setEmployee] = React.useState<Employee>();
  const [user, setUser] = React.useState<User>();
  const [progress, setProgress] = React.useState('tattoo details');
  const [loading, setLoading] = React.useState(true);
  const [doneLoading, setDoneLoading] = React.useState(false);
  const [openRequest, setOpenRequest] = React.useState(false);
  const [openBookingID, setOpenBookingID] = React.useState('');
  const [userError, setUserError] = React.useState(false);
  const { urlPath } = useParams();
  const [authUser, authLoading] = useAuthState(auth);
  const [employeeError, setEmployeeError] = React.useState(false);
  const [authString, setAuthString] = React.useState('');

  React.useEffect(() => {
    if (sessionStorage.getItem('progress') !== null) {
      setProgress(sessionStorage.getItem('progress')!);
    }
  }, []);

  React.useEffect(() => {
    async function fetchData() {
      if (authUser) {
        setAuthorization(authUser).then(async authorization => {
          if (authorization) {
            setAuthString(authorization);
            try {
              const tempUser = await getUser(authorization).catch(() => {
                setUserError(true);
              });

              const tempEmployee = await getEmployeeByUrlPath(
                urlPath,
                authorization,
              ).catch(() => {
                setEmployeeError(true);
              });

              const tempAppointments = await getCustomersAppointments(
                authorization,
              );

              if (tempEmployee && tempAppointments) {
                const openFlag = hasOpenAppointment(
                  tempAppointments,
                  tempEmployee.employeeId,
                );

                if (openFlag) {
                  setOpenRequest(true);
                  setOpenBookingID(openFlag);
                }
              }

              setUser({
                ...tempUser,
                appointments: tempAppointments,
              });

              if (tempEmployee) {
                setEmployee({
                  ...tempEmployee,
                });
              }
            } catch {
              setUserError(true);
            }
          }
        });
        setDoneLoading(true);
      }
      setLoading(false);
    }

    if (loading || (authUser && !doneLoading)) {
      fetchData();
    }
  }, [authUser, employee, loading, doneLoading, urlPath]);

  switch (true) {
    // Everything is loading
    case loading || authLoading:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // User authed, everything else is loading
    case authUser && !(user && employee) && !employeeError:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // User is unauthenticated
    case !authLoading && !authUser:
      return (
        <Container maxWidth="lg" className="login">
          <Login />
        </Container>
      );

    // Everything is loaded and customer can proceed with form
    case authUser && user && employee && !openRequest:
      return (
        <Container maxWidth="lg">
          <EmployeeHeader
            employee={employee!}
            business={employee!.businesses![0]}
          />
          <ProgressBar progress={progress} employee={employee!} />
          <Form
            progress={progress}
            setProgress={setProgress}
            employee={employee!}
            authString={authString}
            user={user!}
          />
        </Container>
      );

    // Everything is loaded but customer has an open request
    case authUser && user && employee && openRequest:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="h1">
            You already have a pending request with this artist. To view the
            status of your request,{' '}
            <Link href={`/booking/${openBookingID}`}>click here.</Link>
          </Typography>
        </Container>
      );

    // Error loading user details
    case userError:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="h1">
            Unexpected error occured. Please reach out to our support team for
            assistance.
          </Typography>
        </Container>
      );

    default:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="h1">
            There was an error loading this artist&apos;s page. Please double
            check that you&apos;ve entered the URL in correctly, and if the
            error persists, reach out to our support team using the help icon
            below.
          </Typography>
        </Container>
      );
  }
}
