import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { differenceInYears, format } from 'date-fns';
import './Form.css';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { FormValues, Employee, User } from '../../interfaces';
import {
  createAppointment,
  uploadAndGetUrls,
} from '../../services/appointment';
import { createUpdateCustomer } from '../../services/customer';

type Props = {
  formValues: FormValues;
  employee: Employee;
  authString: string;
  user: User;
  setStep: any;
  setClickedEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

function Review({
  formValues,
  employee,
  authString,
  user,
  setStep,
  setClickedEdit,
}: Props) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [firstTattooText, setFirstTattooText] = React.useState('');
  const [newCustomerText, setNewCustomerText] = React.useState('');

  const artistName = employee.displayName
    ? employee.displayName
    : `${employee.user.firstName} ${employee.user.lastName}`;

  const questionValues = {
    header: `Review & Submit Your Booking Request with ${artistName}`,
    progress: 6,
  };

  function printImages(images: string[]) {
    const imageURLElems: React.ReactNode[] = [];
    images.forEach(image => {
      imageURLElems.push(
        <Grid item md={4}>
          <img className="image-upload" src={image} alt="Uploaded" />
        </Grid>,
      );
    });
    return imageURLElems;
  }

  function printItem(label: string, value: any, step?: number) {
    if (label === 'Reference Images' || label === 'Area Images') {
      return (
        <div className="review-item">
          <Typography variant="h3">{label}</Typography>
          <Grid container spacing={2}>
            {printImages(value)}
          </Grid>
          <Button
            className="review-link"
            onClick={() => {
              setStep(step);
              setClickedEdit(true);
            }}>
            Edit
          </Button>
        </div>
      );
    }
    if (label === 'Phone Number') {
      return (
        <div className="review-item">
          <Typography variant="h3">{label}</Typography>
          <Typography variant="body1">{value}</Typography>
        </div>
      );
    }
    return (
      <div className="review-item">
        <Typography variant="h3">{label}</Typography>
        <Typography variant="body1">{value}</Typography>
        <Button
          className="review-link"
          onClick={() => {
            setStep(step);
            setClickedEdit(true);
          }}>
          Edit
        </Button>
      </div>
    );
  }

  function getStep(name: string) {
    let num = 0;
    employee.intakeQuestions?.forEach(question => {
      if (question.question === name) {
        num = question.step;
      }
    });
    return num;
  }

  const submitAppointment = React.useCallback(async () => {
    setLoading(true);

    if (
      formValues.birthday &&
      differenceInYears(new Date(), new Date(formValues.birthday)) < 18
    ) {
      setLoading(false);
      setError(true);
      setErrorMessage(
        'Must be 18 years or older to submit an appointment request through Porter. Please reach out to this tattoo artist directly if you are under 18 and want a tattoo with parental supervision',
      );
      return;
    }

    const customerResponse = await createUpdateCustomer(
      formValues,
      user,
      authString,
    ).catch(() => {
      setError(true);
      setLoading(false);
    });

    if (customerResponse) {
      uploadAndGetUrls(formValues, user)
        .then(async imageResponse => {
          // Submit the booking to the API
          const response = await createAppointment(
            formValues,
            employee,
            user,
            authString,
            imageResponse,
          ).catch(() => {
            setLoading(false);
            setError(true);
          });

          // If the booking request was submitted, redirect the user to the new page
          if (response) {
            window.location.replace(`/booking/${response.appointmentId}`);
          } else {
            setLoading(false);
          }
        })
        .catch(() => {
          setError(true);
          setLoading(false);
        });
    }
  }, [authString, user, employee, formValues]);

  React.useEffect(() => {
    if (formValues.firstTattoo && formValues.firstTattoo !== '') {
      if (formValues.firstTattoo === 'true') {
        setFirstTattooText('Yes');
      } else {
        setFirstTattooText('No');
      }
    }

    if (formValues.newCustomer && formValues.newCustomer !== '') {
      if (formValues.newCustomer === 'true') {
        setNewCustomerText('No');
      } else {
        setNewCustomerText('Yes');
      }
    }
  }, [formValues.firstTattoo, formValues.newCustomer]);

  return (
    <Container className="review-container">
      <Typography variant="h2" className="form-header sub">
        {questionValues.header}
      </Typography>
      <Typography variant="body1" className="sub">
        Once you submit your request, {artistName} will review and either accept
        or reject the request, or reach out to you by your phone or email below
        with follow-up questions.
      </Typography>
      <Stack spacing={2} className="review-section">
        {formValues.firstName && formValues.firstName !== ''
          ? printItem(
              'Name',
              `${formValues.firstName} ${formValues.lastName}`,
              getStep('Name'),
            )
          : null}
        {printItem('Phone Number', '248-302-6074')}
        {formValues.email && formValues.email !== ''
          ? printItem('Email', formValues.email, getStep('Other Notes'))
          : null}
        {formValues.birthday
          ? printItem(
              'Birthday',
              format(new Date(formValues.birthday), 'MM/dd/yyyy'),
              getStep('Other Notes'),
            )
          : null}
        {formValues.skinTone
          ? printItem('Skin Tone', formValues.skinTone, getStep('Skin Tone'))
          : null}
        {formValues.firstTattoo && formValues.firstTattoo !== ''
          ? printItem(
              'Is this your first tattoo?',
              firstTattooText,
              getStep('First Tattoo'),
            )
          : null}
        {formValues.newCustomer && formValues.newCustomer !== ''
          ? printItem(
              `Have you worked with ${artistName} before?`,
              newCustomerText,
              getStep('New Customer'),
            )
          : null}

        {formValues.location && formValues.location !== ''
          ? printItem('Body Area', formValues.location, getStep('Location'))
          : null}
        {formValues.details && formValues.details !== ''
          ? printItem('Description', formValues.details, getStep('Details'))
          : null}
        {formValues.size && formValues.size !== ''
          ? printItem('Size', formValues.size, getStep('Size'))
          : null}
        {formValues.color && formValues.color !== ''
          ? printItem('Color', formValues.color, getStep('Color'))
          : null}
        {formValues.budget && formValues.budget !== 0
          ? printItem('Budget', `$${formValues.budget}`, getStep('Budget'))
          : null}
        {formValues.otherNotes && formValues.otherNotes !== ''
          ? printItem(
              'Other Notes',
              formValues.otherNotes,
              getStep('Other Notes'),
            )
          : null}

        {formValues.inspiration && formValues.inspiration !== ''
          ? printItem(
              'Tatoo Inspiration',
              formValues.inspiration,
              getStep('Inspiration'),
            )
          : null}
        {formValues.styles && formValues.styles !== ''
          ? printItem(
              'Preferred Tattoo Styles',
              formValues.styles,
              getStep('Styles'),
            )
          : null}

        {formValues.refImages && formValues.refImages.length > 0
          ? printItem(
              'Reference Images',
              formValues.refImages,
              getStep('Reference images'),
            )
          : null}
        {formValues.areaImages && formValues.areaImages.length > 0
          ? printItem(
              'Area Images',
              formValues.areaImages,
              getStep('Area Images'),
            )
          : null}
      </Stack>
      {loading ? (
        <div>
          <LoadingButton loading variant="contained" className="next">
            Submit
          </LoadingButton>
          <Typography variant="body1" className="loading-text">
            Submitting request.... Please do not refresh the page. This could
            take a minute.
          </Typography>
        </div>
      ) : (
        <Button
          variant="contained"
          className="next done"
          onClick={submitAppointment}>
          Submit
        </Button>
      )}
      {error ? (
        <Typography variant="body1" className="error">
          {errorMessage ||
            'There was an error submitting your booking request. Please refresh the page and try again. If the error persists, reach out to our support team through the help icon below.'}
        </Typography>
      ) : null}
    </Container>
  );
}

export default Review;
