import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Appointment, Employee, User } from '../../interfaces';
import './Booking.css';
import DetailList from './Appointment/DetailList';
import { createRequestOverview } from '../../utils/appointment';

type Props = {
  appointment: Appointment;
  employee: Employee;
  user: User;
};

// TODO - Add images to review submission page
function Overview({ appointment, employee, user }: Props) {
  const detailItems = createRequestOverview(user, appointment);

  const name = employee.displayName
    ? employee.displayName
    : `${employee.user.firstName} ${employee.user.lastName}`;

  const requestedTitle = `Your booking request was submitted! You'll get a notification when ${name} replies.`;
  const rejectedTitle = `Your booking request was rejected by ${name}`;

  return (
    <Container maxWidth="lg" className="review-container">
      <Typography variant="h2" className="form-header">
        {appointment.status === 'REQUESTED' ? requestedTitle : null}
        {appointment.status === 'REJECTED' ? rejectedTitle : null}
      </Typography>
      <DetailList detailItems={detailItems} />
    </Container>
  );
}

export default Overview;
