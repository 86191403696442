import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { FormValues } from '../../interfaces';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
};

const validationSchema = yup.object({
  size: yup.string().required('Required'),
});

function Size({ formValues, setFormValues, nextStep }: Props) {
  const questionValues = {
    header: 'How big do you want the tattoo to be?',
    progress: 2,
  };

  function labelMaker(size: string) {
    let src = '';
    let labelName = '';
    let labelDesc = '';

    switch (size) {
      case 'xs':
        src = '/img/postage-stamp.svg';
        labelName = 'Extra Small';
        labelDesc = '1x1 inches. About the size of a postage stamp';
        break;
      case 'sm':
        src = '/img/credit-card.svg';
        labelName = 'Small';
        labelDesc = '3x3 inches. About the size of a credit card';
        break;
      case 'md':
        src = '/img/hand.svg';
        labelName = 'Medium';
        labelDesc = '7x7 inches. About the size of your hand';
        break;
      case 'lg':
        src = '/img/sleeve.svg';
        labelName = 'Large';
        labelDesc = 'About a half arm sleeve or anything bigger';
        break;
      default:
        src = '';
        labelName = '';
        labelDesc = '';
    }

    return (
      <div className="size-label">
        <img src={src} alt="Tattoo Size Label" className="size-icon" />
        <Typography variant="h3">{labelName}</Typography>
        <Typography variant="body1">{labelDesc}</Typography>
      </div>
    );
  }

  const formik = useFormik({
    initialValues: {
      size: formValues.size,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('size', values.size!);
      nextStep();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h2" className="form-header">
        {questionValues.header}
      </Typography>
      <RadioGroup
        row
        aria-labelledby="radio-size"
        name="size"
        value={formik.values.size}
        onChange={formik.handleChange}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              value="Extra Small"
              control={<Radio />}
              label={labelMaker('xs')}
              labelPlacement="top"
              className="size-label xs"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              value="Small"
              control={<Radio />}
              label={labelMaker('sm')}
              labelPlacement="top"
              className="size-label sm"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              value="Medium"
              control={<Radio />}
              label={labelMaker('md')}
              labelPlacement="top"
              className="size-label md"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              value="Large"
              control={<Radio />}
              label={labelMaker('lg')}
              labelPlacement="top"
              className="size-label lg"
            />
          </Grid>
        </Grid>
      </RadioGroup>
      <Button
        variant="contained"
        className={`next ${formik.isValid ? 'done' : ''}`}
        type="submit">
        Next
      </Button>
    </form>
  );
}

export default Size;
