import * as React from 'react';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useAuthState } from 'react-firebase-hooks/auth';
import { signOut } from 'firebase/auth';
import '../App.css';
import Schedule from '../components/Booking/Schedule';
import Confirm from '../components/Booking/Confirm';
import { Appointment, Employee, User } from '../interfaces';
import Login from '../components/auth/Login';
import { auth } from '../firebase';
import { setAuthorization } from '../services/auth';
import { getUser } from '../services/user';
import { getEmployeeById } from '../services/employee';
import { getAppointment } from '../services/appointment';

export default function ReschedulePage() {
  const { appointmentId } = useParams();
  const [appointment, setAppointment] = React.useState<Appointment>();
  const [employee, setEmployee] = React.useState<Employee>();
  const [user, setUser] = React.useState<User>();
  const [loading, setLoading] = React.useState(true);
  const [userMatch, setUserMatch] = React.useState(false);
  const [authUser, authLoading] = useAuthState(auth);
  const [doneLoading, setDoneLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [selectedDateTime, setSelectedDateTime] = React.useState(new Date());
  const [dateTimeIsSelected, setDateTimeIsSelected] = React.useState(false);
  const [employeeError, setEmployeeError] = React.useState(false);
  const [authString, setAuthString] = React.useState('');

  React.useEffect(() => {
    async function fetchData() {
      if (authUser) {
        setAuthorization(authUser).then(async authorization => {
          if (authorization) {
            setAuthString(authorization);
            try {
              const tempUser = await getUser(authorization).catch(() => {
                setError(true);
              });

              const tempAppointment = await getAppointment(
                appointmentId,
                authorization,
              ).catch(() => {
                setError(true);
              });

              if (tempAppointment.customerId === tempUser.userId) {
                setUserMatch(true);
              }

              const tempEmployee = await getEmployeeById(
                tempAppointment.employeeId,
                authorization,
              ).catch(() => {
                setEmployeeError(true);
              });

              setUser({
                ...tempUser,
              });

              if (tempAppointment) {
                setAppointment({
                  ...tempAppointment,
                });
              }

              if (tempEmployee) {
                setEmployee({
                  ...tempEmployee,
                });
              }
            } catch {
              setError(true);
            }
          }
        });
        setDoneLoading(true);
      }

      setLoading(false);
    }

    if (loading || (authUser && !doneLoading)) {
      fetchData();
    }
  }, [
    appointmentId,
    loading,
    appointment,
    authUser,
    user,
    employee,
    doneLoading,
  ]);

  const signout = React.useCallback(() => {
    signOut(auth);
  }, []);

  switch (true) {
    // Everything is loading
    case loading || authLoading:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // User authed, everything else is loading
    case authUser &&
      !(user && employee && appointment) &&
      !employeeError &&
      !error:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // User is unauthenticated
    case !authLoading && !authUser:
      return (
        <Container maxWidth="lg" className="login">
          <Login />
        </Container>
      );

    // Everything is loaded. Appointment status is undefined
    case authUser && appointment && !appointment.status:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="h1">
            Could not locate your booking. Try again or reach out to our support
            team using the help icon below.
          </Typography>
        </Container>
      );

    // Everything is loaded. User does not match customer ID of the booking they're trying to access
    case authUser && appointment && !userMatch:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="h1">
            The phone number you logged in with does not match the user for this
            booking. Log out, and then enter the phone number you used to create
            this booking to access this page.
          </Typography>
          <Button variant="contained" className="next done" onClick={signout}>
            Sign Out
          </Button>
        </Container>
      );

    // Everything is loaded. Appointment is not yet confirmed
    case authUser &&
      appointment &&
      appointment.status !== 'CONFIRMED' &&
      userMatch:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="h1">
            Your appointment can not be rescheduled since the status is not yet
            confirmed. Please visit{' '}
            <Link href={`/booking/${appointment!.appointmentId}`}>
              this page
            </Link>{' '}
            to check the current status of your appointment.
          </Typography>
        </Container>
      );

    case authUser &&
      appointment &&
      appointment.status === 'CONFIRMED' &&
      userMatch &&
      !dateTimeIsSelected &&
      !employeeError:
      return employee ? (
        <Schedule
          appointment={appointment!}
          employee={employee}
          authString={authString}
          status="reschedule"
          setSelectedDateTime={setSelectedDateTime}
          setDateTimeIsSelected={setDateTimeIsSelected}
        />
      ) : (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    case authUser &&
      appointment &&
      appointment.status === 'CONFIRMED' &&
      userMatch &&
      dateTimeIsSelected &&
      !employeeError:
      return employee ? (
        <Confirm
          employee={employee}
          appointment={appointment!}
          selectedDateTime={selectedDateTime}
          authString={authString}
          status="reschedule"
        />
      ) : (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    case error || employeeError:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="h1">
            There was an error loading your booking. Please reach out to our
            support team using the help icon below. You may be logged in with a
            different account than the one you used to create the booking. Try
            logging out, and make sure to log back in with the same phone number
            you used to create the booking.
          </Typography>
          <Button variant="contained" className="next done" onClick={signout}>
            Sign Out
          </Button>
        </Container>
      );

    default:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="h1">
            There was an error loading your booking. Please reach out to our
            support team using the help icon below.
          </Typography>
        </Container>
      );
  }
}
