import { Typography } from '@mui/material';

interface Props {
  label: string;
  amount: string;
}

export default function PaymentTableRow({ label, amount }: Props) {
  return (
    <div className="payment-row">
      <Typography variant="body1">{label}</Typography>
      <Typography variant="body1">{amount}</Typography>
    </div>
  );
}
