import * as React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { NumericFormat } from 'react-number-format';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormValues } from '../../interfaces';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
};

const validationSchema = yup.object({
  budget: yup
    .number()
    .min(1, 'Please enter a valid budget')
    .required('Required'),
});

function Budget({ formValues, setFormValues, nextStep }: Props) {
  const [value, setValue] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (formValues.budget) {
      setValue(formValues.budget);
    }
  }, [formValues.budget]);

  const questionValues = {
    header: "What's your approximate budget for this tattoo?",
    progress: 5,
  };

  const formik = useFormik({
    initialValues: {
      budget: formValues.budget,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('budget', JSON.stringify(values.budget));
      nextStep();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h2" className="form-header sub">
        {questionValues.header}
      </Typography>
      <Typography variant="body1" className="sub">
        Once you submit your appointment request, the artist will provide an
        estimated quote for your tattoo.
      </Typography>
      <NumericFormat
        placeholder="Budget"
        name="budget"
        thousandSeparator=","
        allowNegative={false}
        customInput={TextField}
        decimalScale={2}
        value={value}
        prefix="$"
        onValueChange={(values: any) =>
          formik.setFieldValue('budget', parseInt(values.value, 10))
        }
      />
      <Button
        variant="contained"
        className={`next ${formik.isValid ? 'done' : ''}`}
        type="submit">
        Next
      </Button>
    </form>
  );
}

export default Budget;
