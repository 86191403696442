import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import PhoneInput from 'react-phone-input-2';
import Link from '@mui/material/Link';
import { signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';
import 'react-phone-input-2/lib/style.css';
import { auth } from '../../firebase';

interface LoginValuesModel {
  step: number;
  userPhone?: string;
  code?: String;
  loggedIn?: Boolean;
  confirmationResult?: any;
}

type Props = {
  loginValues: LoginValuesModel;
  setLoginValues: any;
};

function Phone({ loginValues, setLoginValues }: Props) {
  const [value, setValue] = React.useState('');
  const [valid, setValid] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const header = "What's your phone number?";

  const sendCode = React.useCallback(async () => {
    setLoading(true);

    if (value === '' || value.length < 10) {
      setLoading(false);
      setErrorMessage('Please enter a valid US phone numer');
      setError(true);
      return;
    }

    const verify = new RecaptchaVerifier(
      'recaptcha-container',
      { size: 'invisible' },
      auth,
    );
    signInWithPhoneNumber(auth, `+${value}`, verify)
      .then(result => {
        setLoginValues({
          ...loginValues,
          step: loginValues.step + 1,
          userPhone: `+${value}`,
          confirmationResult: result,
        });
        setError(false);
      })
      .catch(() => {
        window.location.reload();
      });
  }, [loginValues, value, setLoginValues]);

  const handleChange = React.useCallback((val: string) => {
    setValue(val);

    if (val !== '' && val !== undefined) {
      setValid(true);
    }
  }, []);

  return (
    <form>
      <Typography variant="h2" className="form-header">
        {header}
      </Typography>

      <PhoneInput
        country="us"
        onlyCountries={['us']}
        value={value}
        onChange={handleChange}
        inputClass="input-reg"
        containerClass="phone-input"
        countryCodeEditable={false}
      />
      <div id="recaptcha-container" />

      {loading ? (
        <div>
          <LoadingButton loading variant="contained" className="next">
            Submit
          </LoadingButton>
        </div>
      ) : (
        <Button
          variant="contained"
          className={`next ${valid ? 'done' : ''}`}
          id="submit-button"
          onClick={sendCode}>
          Let&apos;s Do This!
        </Button>
      )}

      <Typography variant="body2" className="tos">
        By creating your account you acknowledge that you have read, understood,
        and agree to our{' '}
        <Link href="https://getporter.io/terms-of-us" target="_blank">
          Terms of Use
        </Link>
        .
      </Typography>

      {error ? (
        <Typography variant="body1" color="primary" className="error">
          {errorMessage}
        </Typography>
      ) : null}
    </form>
  );
}

export default Phone;
