import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormValues } from '../../interfaces';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
};

const validationSchema = yup.object({
  details: yup.string().required('Required'),
});

function TattooDetails({ formValues, setFormValues, nextStep }: Props) {
  const questionValues = {
    header: 'Give us all the details about your tattoo.',
    progress: 2,
  };

  const formik = useFormik({
    initialValues: {
      details: formValues.details,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('details', values.details!);
      nextStep();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h2" className="form-header">
        {questionValues.header}
      </Typography>
      <TextField
        id="details"
        name="details"
        value={formik.values.details}
        onChange={formik.handleChange}
        error={formik.touched.details && Boolean(formik.errors.details)}
        helperText={formik.touched.details && formik.errors.details}
        multiline
        placeholder="What inspires you to want this tattoo? What's your story behind it? Why does it have meaning to you?"
        minRows={3}
        className="textarea"
      />
      <Button
        variant="contained"
        className={`next ${formik.isValid ? 'done' : ''}`}
        type="submit">
        Next
      </Button>
    </form>
  );
}

export default TattooDetails;
