import * as React from 'react';
import { DayItem } from '../../../../interfaces';
import DaysRow from './DaysRow';

type Props = {
  dayItems: DayItem[];
  selectedMonth: Date;
  setSelectedDay: React.Dispatch<React.SetStateAction<Date>>;
  setDayIsSelected: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Month({
  dayItems,
  selectedMonth,
  setSelectedDay,
  setDayIsSelected,
}: Props) {
  // Split day items into rows of seven to print weeks
  const weekLength = 7;
  const rows = [];
  for (let i = 0; i < dayItems.length; i += weekLength) {
    rows.push(dayItems.slice(i, i + weekLength));
  }

  return (
    <div className="calendar-rows">
      {rows.map(row => (
        <DaysRow
          dayItems={row}
          selectedMonth={selectedMonth}
          setSelectedDay={setSelectedDay}
          setDayIsSelected={setDayIsSelected}
          key={row[0].date}
        />
      ))}
    </div>
  );
}
