import Typography from '@mui/material/Typography';
import './ArtistHeader.css';
import { Employee, Business } from '../../interfaces';

type Props = {
  employee: Employee;
  business: Business;
};

function EmployeeHeader({ employee, business }: Props) {
  const name = employee.displayName
    ? employee.displayName
    : `${employee.user.firstName} ${employee.user.lastName}`;

  let address = business.streetAddress;
  address += business.streetAddress2 ? `, ${business.streetAddress2}` : '';

  return (
    <div className="artist-header">
      <img
        className="artist-thumbnail"
        src={employee.profileImage}
        alt="Aritst profile"
      />
      {business ? (
        <div className="artist-header-details">
          <Typography variant="h1" gutterBottom>
            {name}
          </Typography>
          <Typography variant="body1">{business.name}</Typography>
          <Typography variant="body1">{address}</Typography>
          <Typography variant="body1">
            {business.city}, {business.state} {business.zip}
          </Typography>
        </div>
      ) : (
        'Loading...'
      )}
    </div>
  );
}

export default EmployeeHeader;
