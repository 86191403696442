import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormValues } from '../../interfaces';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
};

const validationSchema = yup.object({
  inspiration: yup.string().required('Required'),
});

function Inspiration({ formValues, setFormValues, nextStep }: Props) {
  const questionValues = {
    header:
      "Tell us a little more about the inspiration for your tattoo. What's the story?",
    progress: 3,
  };

  const formik = useFormik({
    initialValues: {
      inspiration: formValues.inspiration,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('inspiration', values.inspiration!);
      nextStep();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h2" className="form-header">
        {questionValues.header}
      </Typography>
      <TextField
        id="inspiration"
        name="inspiration"
        value={formik.values.inspiration}
        onChange={formik.handleChange}
        error={formik.touched.inspiration && Boolean(formik.errors.inspiration)}
        helperText={formik.touched.inspiration && formik.errors.inspiration}
        multiline
        placeholder="What inspires you to want this tattoo? What's your story behind it? Why does it have meaning to you?"
        minRows={3}
        className="textarea"
      />
      <Button
        variant="contained"
        className={`next ${formik.isValid ? 'done' : ''}`}
        type="submit">
        Next
      </Button>
    </form>
  );
}

export default Inspiration;
